<template>
  <div class="flexbox">
    <div>
      <div class="dot-loader"></div>
      <div class="dot-loader"></div>
      <div class="dot-loader"></div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
})
</script>

<style scoped>
.flexbox {
  display: flex;
}

.flexbox > div {
  width: 300px;
  height: 70vh;
  flex: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.dot-loader {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #0057B7;
  position: relative;
  -webkit-animation: 1.2s scaleDown ease-in-out infinite;
  animation: 1.2s scaleDown ease-in-out infinite;
}

.dot-loader:nth-child(2) {
  margin: 0 15px;
  -webkit-animation: 1.2s scaleDown ease-in-out infinite 0.15555s;
  animation: 1.2s scaleDown ease-in-out infinite 0.15555s;
}

.dot-loader:nth-child(3) {
  -webkit-animation: 1.2s scaleDown ease-in-out infinite 0.3s;
  animation: 1.2s scaleDown ease-in-out infinite 0.3s;
}

@-webkit-keyframes scaleDown {
  0%,80%,100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scaleDown {
  0%,80%,100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
