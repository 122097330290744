import Vuex, { ActionTree, GetterTree, MutationTree } from 'vuex'

import Vue from 'vue'
import { v4 as guid } from 'uuid';
import { GetQuote } from '../GetQuote';

interface MultifamilyQuoteState {
  currentHouseholdId: undefined | string;
  currentHousehold: HouseholdModel | any;
  households: HouseholdModel[];
}

interface HouseholdModel {
  address: string | undefined;
  name: string | undefined;
  id: string;
  members: MemberData[];
  productId: number | undefined;
  productCoverLevel: string | undefined;
}

interface UpdateHouseholdData {
  address: string;
  name: string;
}

interface RemoveMemberData {
  memberIndex: number;
}

interface UpdateMemberData {
  memberIndex: number;
  memberData: MemberData
}

interface MemberData {
  age: any
  smoker: any;
  name: any;
  surname: any;
  id?: any;
  desc?: any;
  personalDetailsFilled: boolean,
}

Vue.use(Vuex)

export const state: MultifamilyQuoteState = {
  currentHouseholdId: undefined,
  currentHousehold: {},
  households: [
    {
      address: undefined,
      name: undefined,
      id: guid(),
      members: [
        {
          age: undefined,
          smoker: undefined,
          name: undefined,
          surname: undefined,
          id: guid(),
          desc: {},
          personalDetailsFilled: false,
        },
      ],
      productId: undefined,
      productCoverLevel: undefined
    },
    {
      address: undefined,
      name: undefined,
      id: guid(),
      members: [
        {
          age: undefined,
          smoker: undefined,
          name: undefined,
          surname: undefined,
          id: guid(),
          desc: {},
          personalDetailsFilled: false,
        },
      ],
      productId: undefined,
      productCoverLevel: undefined
    }
  ]
}


export const getters: GetterTree<MultifamilyQuoteState, any> = {
  households: (state: MultifamilyQuoteState) => state.households,
  currentHouseholdId: (state: MultifamilyQuoteState) => state.currentHouseholdId,
  currentHousehold: (state: any) => {
    return !!Object.keys(state.currentHousehold).length ? state.currentHousehold : state.households[0];
  },
  currentHouseholdIndex: (state: MultifamilyQuoteState) => state.households.findIndex(household => household.id === state.currentHouseholdId),
  householdLength: (state: MultifamilyQuoteState) => state.households.length,
}

export const mutations: MutationTree<MultifamilyQuoteState> = {
  // MEMBER IN HOUSEHOLD MUTATIONS
  'ADD_MEMBER'(state: MultifamilyQuoteState, householdId: string) {
    const currentHousehold = state.households.find(household => {
      return household.id === householdId
    });

    if(!!currentHousehold) {
      currentHousehold.members.push({
        age: undefined,
        smoker: undefined,
        name: undefined,
        surname: undefined,
        id:  guid(),
        desc: {},
        personalDetailsFilled: false,
      })
    }
  },

  'REMOVE_MEMBER'(state: MultifamilyQuoteState, data: RemoveMemberData) {
    let currentHousehold = state.households.find(household => {
      return household.id === state.currentHouseholdId
    });

    if(!!currentHousehold) {
      currentHousehold.members.splice(data.memberIndex, 1);
    }
  },

  'UPDATE_MEMBER'(state: MultifamilyQuoteState, data: UpdateMemberData) {
    let currentHousehold = state.households.find(household => {
      return household.id === state.currentHouseholdId
    });

    if(!!currentHousehold) {
      currentHousehold.members[data.memberIndex].age = data.memberData.age;
      currentHousehold.members[data.memberIndex].smoker = data.memberData.smoker;
      currentHousehold.members[data.memberIndex].name = data.memberData.name;
      currentHousehold.members[data.memberIndex].surname = data.memberData.surname;
    }
  },

  // HOUSEHOLD MUTATIONS
  'ADD_HOUSEHOLD'(state: MultifamilyQuoteState, householdId: string) {
    state.households.push({
      address: "",
      name: "",
      id: householdId,
      members: [],
      productId: undefined,
      productCoverLevel: undefined
    })
  },

  'REMOVE_HOUSEHOLD'(state: MultifamilyQuoteState, householdId: string) {
    state.households = state.households.filter(household => {
      return household.id !== householdId;
    })
  },

  'UPDATE_HOUSEHOLD'(state: MultifamilyQuoteState, data: UpdateHouseholdData) {
    let currentHousehold = state.households.find(household => {
      return household.id === state.currentHouseholdId
    });

    if (!!currentHousehold) {
      if (typeof data.name !== 'undefined') {
        currentHousehold.name = data.name;
      }
      if (typeof data.address !== 'undefined') {
      currentHousehold.address = data.address;
      }
    }
  },

  'SELECT_HOUSEHOLD'(state: MultifamilyQuoteState, householdId: string) {
    state.currentHouseholdId = householdId

    let currentHousehold = state.households.find(household => household.id === householdId);

    if (!!currentHousehold) {
      state.currentHousehold = currentHousehold
    }
  },

  'CLEAR_DATA'(state: MultifamilyQuoteState) {
    state.currentHouseholdId = undefined;
    state.currentHousehold = {};
    state.households = [
      {
        address: undefined,
        name: undefined,
        id: guid(),
        members: [
          {
            age: undefined,
            smoker: undefined,
            name: undefined,
            surname: undefined,
            id: guid(),
            desc: {},
            personalDetailsFilled: false,
          },
        ],
        productId: undefined,
        productCoverLevel: undefined
      },
      {
        address: undefined,
        name: undefined,
        id: guid(),
        members: [
          {
            age: undefined,
            smoker: undefined,
            name: undefined,
            surname: undefined,
            id: guid(),
            desc: {},
            personalDetailsFilled: false,
          },
        ],
        productId: undefined,
        productCoverLevel: undefined
      }
    ];
  },

  'SET_MULTIFAMILY_MEMBER_DESCRIPTION' (state: MultifamilyQuoteState, data) {
    if (!data.householdId) {
      state.households[0].members[0].desc = Object.assign(state.households[0].members[0].desc, data.payload)
    } else {
      let currentHousehold = state.households.find(household => household.id === data.householdId);
      if (!!currentHousehold) {
        currentHousehold.members[data.index].desc = Object.assign(currentHousehold.members[data.index].desc, data.payload)
      }
    }
  },

  'SET_HOUSEHOLD_PRODUCT_ID' (state: MultifamilyQuoteState, data) {
    let currentHousehold = state.households.find(household => household.id === data.householdId);

    if (!!currentHousehold) {
      currentHousehold.productId = data.value
    }
  },

  'SET_HOUSEHOLD_PRODUCT_COVER_LEVEL' (state: MultifamilyQuoteState, data) {
    let currentHousehold = state.households.find(household => household.id === data.householdId);

    GetQuote.state.quotePayload.productCoverLevel = data.value
    if (!!currentHousehold) {
      currentHousehold.productCoverLevel = data.value
    }
  },
  'MULTI_PERSONAL_DETAILS_FILLED' (state: MultifamilyQuoteState, data) {
    let currentHousehold = state.households.find(household => household.id === data.householdId);

    if (!!currentHousehold) {
      currentHousehold.members[data.index].personalDetailsFilled = true
    }
  },
}

export const actions: ActionTree<MultifamilyQuoteState, any> = {
  // MEMBER IN HOUSEHOLD ACTIONS
  addMemberAction({commit}, householdId: string) {
    commit('ADD_MEMBER', householdId);
  },

  removeMemberAction({commit}, data: RemoveMemberData) {
    commit('REMOVE_MEMBER', data);
  },

  updateMemberAction({commit}, data: UpdateMemberData) {
    commit('UPDATE_MEMBER', data);
  },

  // HOUSEHOLD ACTIONS
  addHouseholdAction({commit}, selectHousehold: boolean) {
    let householdId = guid();
    commit('ADD_HOUSEHOLD', householdId);
    if (selectHousehold) {
      commit('SELECT_HOUSEHOLD', householdId);
    }
    commit('ADD_MEMBER', householdId);

  },
  removeHouseholdAction({commit, state}, householdId: string) {
    commit('REMOVE_HOUSEHOLD', householdId);
    commit('SELECT_HOUSEHOLD', state.households[0].id)
  },
  updateHouseholdAction({commit}, data: UpdateHouseholdData) {
    commit('UPDATE_HOUSEHOLD', data);
  },
  selectHouseholdAction({commit}, householdId: string) {
    commit('SELECT_HOUSEHOLD', householdId)
  },
  multifamilyClearData({commit}) {
    commit('CLEAR_DATA');
  },
  setMultifamilyMemberDesc({commit}, data) {
    commit('SET_MULTIFAMILY_MEMBER_DESCRIPTION', data)
  },
  multiPersonalDetailsFilled({commit}, data) {
    commit('MULTI_PERSONAL_DETAILS_FILLED', data)
  },
}

export const MultifamilyQuote = {
  state,
  getters,
  actions,
  mutations
};
