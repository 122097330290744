import 'whatwg-fetch'
import { parseJwt, isTokenExpired, logout } from '../utils/auth';
import { default as _api } from '@/api'
import store from '../store/index';
import _ from 'lodash';

let api;
let apiUrl = '';
let refreshTokenExpirationDate;
let isTokenRefreshing = false;
const logoutTimeout = 11 * 60000; // 11 min
const logoutWarningTimeout = 10 * 60000; // 10 min
const refreshTokenExpirationOffsetMinutes = 25;

const defaultHeaders = {
  'Content-Type': 'application/json',
};

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function getConfiguration() {
  let configuration = store.getters.config;

  while (!configuration) {
    await sleep(200);
    configuration = store.getters.config;
  }

  return configuration;
}


export const logoutAndClear = async (redirect = true) => {
  const configuration = await getConfiguration();
  
  const currentLocation = window.location;
  if(currentLocation.href.includes("/invite") || currentLocation.href.includes("/get-quote"))
  {
    redirect = false;
  }
  
  logout(configuration.publicWebsites.main, redirect);
  refreshTokenExpirationDate = null;
}

const displayLogoutWarning = () => {
  store.commit("SET_SHOW_LOGOUT_WARNING", true);
};

export const forceLogout = _.debounce(logoutAndClear, logoutTimeout);
export const logoutWarning = _.debounce(displayLogoutWarning, logoutWarningTimeout);

(() => {
  document.addEventListener('keydown', async (event) => {
    const accessToken = localStorage.getItem("ACCESS_TOKEN");
    if (accessToken && !store.state.showLogoutWarning) {
      logoutWarning();
      forceLogout();
      await checkAndRefreshToken();
    }
  });

  document.addEventListener('click', async (event) => {
    const accessToken = localStorage.getItem("ACCESS_TOKEN");
    if (accessToken && !store.state.showLogoutWarning) {
      logoutWarning();
      forceLogout();
      await checkAndRefreshToken();
    }
  });
})()

const getNewRefreshToken = async () => {
  const accessToken = localStorage.getItem("ACCESS_TOKEN");
  const refreshToken = localStorage.getItem('REFRESH_TOKEN');

  if (!refreshToken || !accessToken) {
    logoutAndClear();
    console.error('refreshToken or accessToken missing, logging out');
    return;
  }

  const url = `${apiUrl}api/authorization/getNewRefreshToken`;
  return fetch(url, {
    headers: {
      ...defaultHeaders,
      ...refreshToken ? { 'RefreshToken': refreshToken } : null,
      ...accessToken ? { 'AccessToken': accessToken } : null,
    },
    method: 'POST',
  })
    .then(checkStatus)
    .then(async (response) => {
      const token = await response.text();
      const date = new Date();
      date.setMinutes(date.getMinutes() + refreshTokenExpirationOffsetMinutes);
      localStorage.setItem("REFRESH_TOKEN", token);
      localStorage.setItem('REFRESH_TOKEN_EXPIRATION', date.getTime().toString());
      refreshTokenExpirationDate = date;
      await refreshAccessToken();
    })
    .catch(async (error) => {
      await logoutAndClear();
      console.error('get new refresh token failed', error);
    });
};

export const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('REFRESH_TOKEN');
  const url = `${apiUrl}api/authorization/getAccessToken`;
  return fetch(url, {
    headers: {
      ...defaultHeaders,
      ...refreshToken ? { 'RefreshToken': refreshToken } : null,
    },
    method: 'POST'
  })
    .then(checkStatus)
    .then(async (response) => {
      const token = await response.text();
      localStorage.setItem("ACCESS_TOKEN", token);
      forceLogout();
      logoutWarning();
    })
    .catch(async (error) => {
      // if refresh fails try get new refresh token and refresh access token
      if (error.message === "Unauthorized") {
        await getNewRefreshToken();
      }
      else {
        await logoutAndClear(false);
      }
    });
};

const checkAndRefreshToken = async () => {
  const refreshToken = localStorage.getItem('REFRESH_TOKEN');
  if (refreshToken) {
    let expired = false;
    const accessToken = localStorage.getItem("ACCESS_TOKEN");

    if (accessToken) {
      const decodedToken = parseJwt(accessToken);
      expired = isTokenExpired(decodedToken);
    }

    if (!isTokenRefreshing) {
      if (!accessToken || expired) {
        isTokenRefreshing = true;
        await refreshAccessToken();
        isTokenRefreshing = false;
      }
    }
    else {
      await wait();
    }

    if (refreshTokenExpirationDate < new Date()) {
      await getNewRefreshToken();
    }
  }
};

const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  else if (response.status === 401) {
    const error = new Error(response.statusText);
    error.message = "Unauthorized";
    throw error;
  } else {
    const error = new Error(response.statusText);
    error.message = response;
    throw error;
  }
};

const getHeaders = (headers, body?, url?) => {
  let requestHeaders = {
    ...headers
  };

  if (!(body && body instanceof FormData)) {
    requestHeaders = {
      ...requestHeaders,
      'Content-Type': 'application/json'
    };
  }

  const accessToken = localStorage.getItem("ACCESS_TOKEN");
  const refreshToken = localStorage.getItem('REFRESH_TOKEN');

  // do not override authorization header if there is one already
  if (!requestHeaders.authorization && accessToken) {
    requestHeaders = {
      ...requestHeaders,
      'Authorization': `Bearer ${accessToken}`
    };
  }

  if (url === `${apiUrl}api/authorization/getAccessToken`) {
    requestHeaders = {
      ...requestHeaders,
      ...refreshToken ? { 'RefreshToken': refreshToken } : null,
    };
  }

  return requestHeaders;
};

const wait = async () => {
  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  while (isTokenRefreshing) {
    await sleep(500);
  }
};

const beforeRequest = async (url) => {
  if (!api || !apiUrl) {
    api = await _api;
    apiUrl = api.apiSpec.API;
  }

  const accessToken = localStorage.getItem("ACCESS_TOKEN");
  const refreshToken = localStorage.getItem('REFRESH_TOKEN');
  const refreshTokenExpirationDateBeforeParsing = localStorage.getItem('REFRESH_TOKEN_EXPIRATION');
  if (refreshTokenExpirationDateBeforeParsing) {
    refreshTokenExpirationDate = new Date(parseInt(refreshTokenExpirationDateBeforeParsing));
  }

  if (!isTokenRefreshing) {
    if (url !== `${apiUrl}api/authorization/getAccessToken` && url !== `${apiUrl}api/authorization/getNewRefreshToken`) {
      await checkAndRefreshToken();
    }
    else {
      isTokenRefreshing = true;
    }
    if (accessToken) {
      logoutWarning();
      forceLogout();
    }
  }
  else {
    await wait();
  }
};

const post = async (
  url,
  body?: Blob | BufferSource | FormData | URLSearchParams | ReadableStream<Uint8Array> | string,
  headers?: object | null
) => {
 
  await beforeRequest(url);

  const request = fetch(url, {
    headers: {
      ...getHeaders(headers, body, url)
    },
    method: 'POST',
    body: body || JSON.stringify({})
  });

  return request
    .then(checkStatus)
    .then(async response => {
      if (url === `${apiUrl}api/authorization/getAccessToken`) {
        const clonedResponse = response.clone();
        localStorage.setItem("ACCESS_TOKEN", await clonedResponse.text())
        logoutWarning();
        forceLogout();
      }

      return response;
    })
    .catch(async (error) => {
      if (url === `${apiUrl}api/authorization/getAccessToken`) {
        const accessToken = localStorage.getItem("ACCESS_TOKEN");
        if (!accessToken) {
          const configuration = await getConfiguration();
          logoutAndClear();
        }
        else
          await getNewRefreshToken();
      }
      else {
        console.error('request failed', error);
      }
      throw error;
    })
    .finally(() => {
      if (url === `${apiUrl}api/authorization/getAccessToken`) {
        isTokenRefreshing = false;
      }
    });
};

const get = async (
  url,
  headers?: object | null
) => {
  await beforeRequest(url);

  const request = fetch(url, {
    headers: {
      ...getHeaders(headers)
    },
    method: 'GET'
  });

  return request
    .then(checkStatus)
    .catch((error) => {
      console.error('request failed', error);
    });
};

const delete_ = async (
  url,
  headers?: object | null
) => {
  await beforeRequest(url);

  const request = fetch(url, {
    headers: {
      ...getHeaders(headers)
    },
    method: 'DELETE'
  });

  return request
    .then(checkStatus)
    .catch((error) => {
      console.error('request failed', error);
    });
};

const patch = async (
  url,
  body?: Blob | BufferSource | FormData | URLSearchParams | ReadableStream<Uint8Array> | string,
  headers?: object | null
) => {
  await beforeRequest(url);

  const request = fetch(url, {
    headers: {
      ...getHeaders(headers),
      'Content-Type': 'application/json-patch+json'
    },
    method: 'PATCH',
    body: body || JSON.stringify({})
  });

  return request
    .then(checkStatus)
    .catch((error) => {
      console.error('request failed', error);
    });
};

const put = async (
  url,
  body?: Blob | BufferSource | FormData | URLSearchParams | ReadableStream<Uint8Array> | string,
  headers?: object | null
) => {
  await beforeRequest(url);

  const request = fetch(url, {
    headers: {
      ...getHeaders(headers, body)
    },
    method: 'PUT',
    body: body || JSON.stringify({})
  });

  return request
    .then(checkStatus)
    .catch((error) => {
      console.error('request failed', error);
    });
};

const apiCall = {
  post,
  get,
  patch,
  put,
  delete_
};

export default apiCall;
