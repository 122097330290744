import { parseJwt } from "@/utils/auth";

const redirectWithForm = (url, accessToken, params) => {
  var form = document.createElement("form");
  form.method = "POST";
  form.action = url;
  var formField = document.createElement("input");
  formField.style.display = "none";
  formField.value = accessToken;
  formField.name = "access_token";
  form.appendChild(formField);
  if (params) {
    Object.entries(params).forEach(([key, value], _) => {
      let input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", key);
      input.setAttribute("value", value);
      form.appendChild(input);
    });
  }
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

const redirectWithQueryString = (url, params) => {
  if (params) {
    let joinChar = "?";
    Object.entries(params).forEach(([key, value], _) => {
      url = `${url}${joinChar}${key}=${value}`;
      joinChar = "&";
    });
  }
  window.location.href = url;
};

const loggedUserMethods = {
  inject: ["api"],
  methods: {
    sendUsMessage() {
      const config = this.$store.state.config;
      const renewals = this.$store.state.Renewals;
      const isGroup = renewals.productId === 957;
      if (isGroup) {
        const params = config.secureAreaGatewayEndpointEmailUsParam;
        this.accessSecureArea(params);
      } else {
        const relativeUrl = config.myWpaMessagesEndpoint;
        const params = config.myWpaMessagesEndpointEmailUsParam;
        this.accessMyWPA(relativeUrl, params);
      }
    },
    accessMyWPA(relativeUrl, params) {
      const config = this.$store.state.config;
      const url = `${config.myWpaUrl}${relativeUrl}`;
      redirectWithQueryString(url, params);
    },
    accessSecureArea(params) {
      const config = this.$store.state.config;
      const token = localStorage.getItem("ACCESS_TOKEN");
      if (token) {
        const secureAreaGatewayUrl = `${config.secureAreaUrl}${config.secureAreaGatewayEndpoint}`;
        redirectWithForm(secureAreaGatewayUrl, token, params);
      }
    },
    accessNewSecureAreaPersonHomepage(params) {
      const config = this.$store.state.config;
      const token = localStorage.getItem("ACCESS_TOKEN");
      if (token) {
        const secureAreaGatewayUrl = `${config.newSecureAreaUrl}${config.newSecureAreaPersonHome}`;
        redirectWithForm(secureAreaGatewayUrl, token, params);
      }
    },
    isCustomer() {
      const token = localStorage.getItem("ACCESS_TOKEN");
      if (!token) {
        return false;
      }
      const userData = parseJwt(token);
      return !!userData.CustomerId;
    },
    async signOut(goHome = true) {
      try {
        if (goHome) {
          localStorage.clear();
          this.$router.push({ name: "Home" });
        } else {
          try {
            const api = await this.api();
            await api.logout();
          } catch (err) {
            console.error(err.message);
          }
          const config = this.$store.state.config;
          localStorage.clear();
          window.location.href = config.publicWebsites.main;
        }
      } catch (out_ex) {
        console.error(out_ex);
      }
    },
  },
};
export default loggedUserMethods;
