const renewalsSetup = {
  inject: ["api"],
  methods: {
    async getLiteratureForCustomer() {
      const api = await this.api();
      const renewalLiterature = await api.getLiteratureForCustomer();
      this.$store.commit('SET_RENEWAL_LITERATURE', renewalLiterature)
    },
    async getQuotesForRenewal() {
      const api = await this.api();
      const renewalQuotes = await api.getQuotesForRenewal();
      this.$store.commit('SET_RENEWAL_QUOTES_LIST', renewalQuotes)
    },
    async getRenewalForCustomer() {
      const api = await this.api();
      const renewalData = await api.getRenewalForCustomer();     
      this.$store.commit('SET_CURRENT_QUOTE', renewalData.currentQuote);
      this.$store.commit('SET_RENEWAL_QUOTE', renewalData.renewalQuote);
      this.$store.commit('SET_DEFAULT_QUOTE', renewalData.renewalQuote); // for reset to default purposes
    },
    async getUpDownForCustomer() {
      const api = await this.api();
      const renewalData = await api.getUpDownForCustomer();
      this.$store.commit('SET_RENEWAL_UP_DOWNS', renewalData)
    },
    isMultiFamily() {
      try {
        return this.$store.state.Renewals.currentQuote.productId === 957 || this.$store.state.Renewals.currentQuote.productId === 951;
      } catch {
        return false;
      }     
     }
  }
}
export default renewalsSetup