const CONFIG_FILE_PATH = "/config.json";
import apiCall from "../api/fetch-middleware";
import "whatwg-fetch";

export const passThroughOrMapToQueryParams = (params) => {
  return Object.keys(params)
    .filter((p) => {
      return params[p] !== undefined && params[p] !== null;
    })
    .map((p) => `${p}=${params[p]}`)
    .join("&");
};

export function buildRecommendedProductFetcher(apiSpec) {
  return async function GETRecommendedProduct(pageStack) {
    return apiCall.post(`${apiSpec.API}api/product/seek`, JSON.stringify(pageStack)).then((r) => r.json());
  };
}

export function buildApplicationSummaryFetcher(apiSpec) {
  return async function GETApplicationSummary() {
    return apiCall.get(`${apiSpec.API}api/application/summary`).then((r) => r.json());
  };
}

export function buildQuoteSummaryFetcher(apiSpec) {
  return async function GETQuoteSummary() {
    return apiCall.get(`${apiSpec.API}api/quote/summary`).then((r) => r.json());
  };
}

export function buildDeclarationsFetcher(apiSpec) {
  return async function getDeclarations(productId, PageTypeId, ApplicationId, MembershipRequestId) {
    console.assert(productId, "productId is required");
    const urlParams = passThroughOrMapToQueryParams({
      PageTypeId: PageTypeId,
      ApplicationId: ApplicationId || undefined,
      MembershipRequestId: MembershipRequestId || undefined,
    });
    const req = apiCall.get(`${apiSpec.API}api/declaration/product/` + productId + `?` + urlParams);
    try {
      const res = await req;
      if (!res.ok) {
        throw await res.json();
      }
      const data = await res.json();
      return data;
    } catch (err) {
      throw err;
    }
  };
}

export function buildDocumentFetcher(apiSpec) {
  return async function getDocument(documentName) {
    const req = apiCall.get(`${apiSpec.API}api/declaration/document/` + documentName + `/content`);
    try {
      const res = await req;
      if (!res.ok) {
        throw await res.text();
      }
      const data = await res.text();
      return data;
    } catch (err) {
      throw err;
    }
  };
}

export function buildDeclarationAnswers(apiSpec) {
  return async function postDeclaration(data) {
    const req = apiCall.post(`${apiSpec.API}api/declaration/create`, JSON.stringify(data));
    try {
      const res = await req;
      if (!res.ok) {
        throw await res.json();
      }
      const data = await res.json();
      return data;
    } catch (err) {
      throw err;
    }
  };
}

export function buildAccessTokenFetcher(apiSpec) {
  return async function getAccessToken() {
    const req = apiCall.post(`${apiSpec.API}api/authorization/getAccessToken`);
    try {
      const res = await req;
      if (!res.ok) {
        throw await res.json();
      }
      const data = await res.text();
      const token = data;
      localStorage.setItem("ACCESS_TOKEN", token);
      return data;
    } catch (err) {
      localStorage.removeItem("ACCESS_TOKEN");
      throw err;
    }
  };
}

const DEFAULT_HEADERS = {
  method: "POST",
  headers: {
    "Content-Type": "application/json-patch+json",
    Authorization: localStorage.getItem("ACCESS_TOKEN") ? `Bearer ${localStorage.getItem("ACCESS_TOKEN")}` : "",
  },
};

const getMemberFromMultiFamily = (member) => {
  const { id, ...rest } = member;
  return rest;
};

export function buildQuickQuoteFetcher(apiSpec) {
  return function postQuickQuote(store, json = true, overwriteQuotePayload = undefined) {
    const payload = overwriteQuotePayload || store.getters.quotePayloadWithoutMemberIds;
    return apiCall.post(`${apiSpec.API}api/quickquote`, JSON.stringify(payload)).then((r) => (json ? r.json() : r));
  };
}

export function buildLogoutFetcher(apiSpec) {
  return async function logout() {
    const req = await apiCall.post(`${apiSpec.API}api/authorization/logout`);
  };
}

export function buildQuickQuoteCustomiseFetcher(apiSpec) {
  return function postQuickQuoteCustomise(payload, json = true) {
    return apiCall.post(`${apiSpec.API}api/quickquote/customise`, JSON.stringify(payload)).then((r) => (json ? r.json() : r));
  };
}

export function buildGetQuoteById(apiSpec) {
  return async function getQuoteById(id, json = true) {
    return apiCall.get(`${apiSpec.API}api/quote/${id}`).then((r) => (json ? r.json() : r));
  };
}

export function buildGetQuickQuoteById(apiSpec) {
  return async function getQuickQuoteById(id, json = true) {
    return apiCall.get(`${apiSpec.API}api/quote/${id}/quickquote`).then((r) => (json ? r.json() : r));
  };
}

export function buildMedicalDeclarationCategoryPost(apiSpec, payload) {
  return function getMedicalDeclarationCategoryPost(store, json = true) {
    const url = new URL(`${apiSpec.API}api/flow/medicaldeclaration/furtherQuestion/${payload[0].questionFlowId}`);
    return apiCall.post(url, JSON.stringify(payload)).then((r) => (json ? r.json() : r));
  };
}

export function buildCurrentInsurancePost(apiSpec) {
  return async function currentInsurancePost(data, appId, householdId) {
    const req = apiCall.put(`${apiSpec.API}api/application/` + appId + `/household/` + householdId + `/currentInsurance`, JSON.stringify(data));
    try {
      const res = await req;
      if (!res.ok) {
        throw await res.json();
      }
      const data = await res.text();
      return data;
    } catch (err) {
      throw err;
    }
  };
}

export function buildCurrentInsuranceFetcher(apiSpec) {
  return async function getCurrentInsurance(appId, householdId) {
    const req = apiCall.get(`${apiSpec.API}api/application/` + appId + `/household/` + householdId + `/currentInsurance`);
    try {
      const res = await req;
      if (!res.ok) {
        throw await res.json();
      }
      const data = await res.json();
      return data;
    } catch (err) {
      throw err;
    }
  };
}

export function buildFilesUploadPost(apiSpec) {
  return async function postFilesUpload(appId, clientId, data) {
    const formData = new FormData();
    data.forEach((e, k) => formData.append("files", data[k]));

    const req = apiCall.post(`${apiSpec.API}api/application/` + appId + `/files?clientId=` + clientId, formData);
    try {
      const res = await req;
      if (!res.ok) {
        throw await res.json();
      }
      const data = await res.text();
      return data;
    } catch (err) {
      throw err;
    }
  };
}

export function buildUploaderExistingFiles(apiSpec) {
  return async function getUploaderExistingFiles(appId, clientId) {
    const req = apiCall.get(`${apiSpec.API}api/application/${appId}/upload/existingFiles?clientId=` + clientId);
    try {
      const res = await req;
      const data = await res.json();
      return data;
    } catch (err) {
      throw err;
    }
  };
}

export function buildUploaderEnabledPost(apiSpec) {
  return async function postUploaderEnabled(clientId) {
    const req = apiCall.post(`${apiSpec.API}api/application/upload/enabled?clientId=` + clientId);
    try {
      const res = await req;
      const data = await res.text();
      return data;
    } catch (err) {
      throw err;
    }
  };
}

export function buildUploaderFilesValidationGet(apiSpec) {
  return async function getUploaderFilesValidation(accessToken) {
    const requestHeader = {
      Authorization: `Bearer ${accessToken}`,
    };
    // apiCall is not used here because we don't call Webbuy API
    const req = fetch(apiSpec.validateFilesAPI, {
      method: "GET",
      headers: requestHeader,
    });

    try {
      const res = await req;
      if (!res.ok) {
        throw await res.json();
      }
      const data = await res.json();
      return data;
    } catch (err) {
      throw err;
    }
  };
}

export function buildGetUploadAccessToken(apiSpec) {
  return async function getUploadAccessToken(appId, clientId) {
    let req;
    if (appId > 0) req = apiCall.get(`${apiSpec.API}api/application/${appId}/${clientId}/getAccessToken`);
    else req = apiCall.get(`${apiSpec.API}api/invite/${-appId}/${clientId}/getAccessToken`);
    try {
      const res = await req;
      if (!res.ok) {
        throw await res.json();
      }
      return res.text();
    } catch (err) {
      throw err;
    }
  };
}

export function buildSendFiles(apiSpec) {
  return async function sendFiles(files, accessToken) {
    const formData = new FormData();
    files.forEach((e, k) => formData.append("FormFiles", files[k]));

    const requestHeader = {
      Authorization: `Bearer ${accessToken}`,
    };

    // apiCall is not used here because we don't call Webbuy API
    const req = fetch(apiSpec.sendFilesAPI, {
      method: "POST",
      headers: requestHeader,
      body: formData,
    });

    try {
      const res = await req;
      if (!res.ok) {
        throw await res.json();
      }
      const data = await res.json();
      return data;
    } catch (err) {
      throw err;
    }
  };
}

export function buildSaveUpload(apiSpec) {
  return async function saveUpload(uploadFileResponse) {
    const req = apiCall.post(`${apiSpec.API}api/application/saveUpload`, JSON.stringify(uploadFileResponse));
    try {
      const res = await req;
      if (!res.ok) {
        throw await res.json();
      } else {
        return res;
      }
    } catch (err) {
      throw err;
    }
  };
}

export function registerUser(apiSpec) {
  return async (data) => {
    const req = apiCall.post(`${apiSpec.API}api/authorization/register`, JSON.stringify(data));

    try {
      const res = await req;
      if (!res.ok) {
        throw await res.json();
      }
      return res.json();
    } catch (err) {
      throw err;
    }
  };
}

export function registerFlexyUser(apiSpec) {
  return async (data) => {
    const req = apiCall.post(`${apiSpec.API}api/authorization/register/buycustomer`, JSON.stringify(data));

    try {
      const res = await req;
      if (!res.ok) {
        throw await res.json();
      }
      return res.json();
    } catch (err) {
      throw err;
    }
  };
}

export function getWebSources(apiSpec) {
  return async (data) => {
    const req = apiCall.post(`${apiSpec.API}api/authorization/websources`, JSON.stringify(data));
    try {
      const res = await req;
      if (!res.ok) {
        throw await res.json();
      }
      return res.json();
    } catch (err) {
      throw err;
    }
  };
}

export function registerCustomer(apiSpec) {
  return async (data) => {
    const req = apiCall.post(`${apiSpec.API}api/authorization/register/customer`, JSON.stringify(data));

    try {
      const res = await req;
      if (!res.ok) {
        throw await res.json();
      }
      return res.json();
    } catch (err) {
      throw err;
    }
  };
}

export function registerUserAuthorize(apiSpec) {
  return async (data, accessToken) => {
    const req = apiCall.post(`${apiSpec.API}api/authorization/register/authorize`, JSON.stringify(data), { Authorization: `Bearer ${accessToken}` });

    try {
      const res = await req;
      if (!res.ok) {
        throw await res.json();
      }
      return res.json();
    } catch (err) {
      throw err;
    }
  };
}

export function completeRegistration(apiSpec) {
  return async (data, accessToken) => {
    const req = apiCall.post(`${apiSpec.API}api/authorization/register/complete`, JSON.stringify(data), { Authorization: `Bearer ${accessToken}` });

    const res = await req;

    const text = await res.text();

    try {
      const data = JSON.parse(text);
      return data;
    } catch (error) {
      return text;
    }
  };
}

export function resendActivationCode(apiSpec) {
  return async (registrationId) => {
    const res = await apiCall.post(`${apiSpec.API}api/authorization/register/${registrationId}/sendcode`);

    const text = await res.text();

    try {
      const data = JSON.parse(text);
      return data;
    } catch (error) {
      return text;
    }
  };
}

export function getFPN(apiSpec) {
  return async (data) => {
    const req = apiCall.post(`${apiSpec.API}api/authorization/get-fpn`, JSON.stringify(data));

    const res = await req;

    const text = await res.text();

    try {
      const data = JSON.parse(text);
      return data;
    } catch (error) {
      return text;
    }
  };
}

export function approveFPN(apiSpec) {
  return async (data) => {
    const req = apiCall.post(`${apiSpec.API}api/authorization/save-fpn`, JSON.stringify(data));

    const res = await req;

    const text = await res.text();

    try {
      const data = JSON.parse(text);
      return data;
    } catch (error) {
      return text;
    }
  };
}

export function buildGetRenewalForCustomer(apiSpec) {
  return function getRenewalForCustomer() {
    return apiCall.get(`${apiSpec.API}api/renewal`).then((r) => r.json());
  };
}
export function buildGetUpDownForCustomer(apiSpec) {
  return function getUpDownForCustomer() {
    return apiCall.get(`${apiSpec.API}api/renewal/upDown`).then((r) => r.json());
  };
}
export function buildPostRenewalQuote(apiSpec) {
  return function postRenewalQuote(requestBody) {
    return apiCall.post(`${apiSpec.API}api/renewal/quote`, JSON.stringify(requestBody)).then((r) => {
      return r.ok ? r.json() : null;
    });
  };
}
export function buildProcessRenewalQuote(apiSpec) {
  return function processRenewalQuote(requestBody) {
    return apiCall.post(`${apiSpec.API}api/renewal/process`, JSON.stringify(requestBody)).then((r) => {
      return r.ok ? r.json() : null;
    });
  };
}
export function buildGetQuotesForRenewal(apiSpec) {
  return function getRenewalQuotes() {
    return apiCall.get(`${apiSpec.API}api/renewal/quotes`).then((r) => r.json());
  };
}
export function buildGetLiteratureForCustomer(apiSpec) {
  return function getLiteratureForCustomer() {
    return apiCall.get(`${apiSpec.API}api/renewal/literature`).then((r) => r.json());
  };
}

export function buildCalculateQuickQuote(apiSpec) {
  return function calculateQuickQuote(requestBody) {
    return apiCall.post(`${apiSpec.API}api/quickquote`, JSON.stringify(requestBody)).then((r) => {
      return r.ok ? r.json() : null;
    });
  };
}

export function buildCalculateRenewalQuickQuoteById(apiSpec) {
  return function calculateRenewalQuickQuoteById(quoteId) {
    return apiCall.get(`${apiSpec.API}api/renewal/quickquote/${quoteId}`).then((r) => r.json());
  };
}

export function buildCalculateRenewalQuickQuote(apiSpec) {
  return function calculateRenewalQuickQuote(requestBody) {
    return apiCall.post(`${apiSpec.API}api/renewal/quickquote`, JSON.stringify(requestBody)).then((r) => {
      return r.ok ? r.json() : null;
    });
  };
}

export function buildRecalculateQuickQuote(apiSpec) {
  return function recalculateQuickQuote(requestBody) {
    return apiCall.post(`${apiSpec.API}api/quickquote/customise`, JSON.stringify(requestBody)).then((r) => {
      return r.ok ? r.json() : null;
    });
  };
}

export function buildRecalculateRenewalQuickQuote(apiSpec) {
  return function recalculateRenewalQuickQuote(requestBody) {
    return apiCall.post(`${apiSpec.API}api/renewal/quickquote/customise`, JSON.stringify(requestBody)).then((r) => {
      return r.ok ? r.json() : null;
    });
  };
}

export function buildApi(apiSpec) {
  console.assert(apiSpec.API, "config's API parameter is not provided");
  return {
    recommendedProduct: buildRecommendedProductFetcher(apiSpec),
    quickQuote: buildQuickQuoteFetcher(apiSpec),
    quickQuoteCustomise: buildQuickQuoteCustomiseFetcher(apiSpec),
    applicationSummary: buildApplicationSummaryFetcher(apiSpec),
    quoteSummary: buildQuoteSummaryFetcher(apiSpec),
    medicalDeclarationCategoryPost: buildMedicalDeclarationCategoryPost(apiSpec),
    declarations: buildDeclarationsFetcher(apiSpec),
    document: buildDocumentFetcher(apiSpec),
    accessToken: buildAccessTokenFetcher(apiSpec),
    logout: buildLogoutFetcher(apiSpec),
    declarationsAnswers: buildDeclarationAnswers(apiSpec),
    currentInsurancePost: buildCurrentInsurancePost(apiSpec),
    currentInsuranceFetcher: buildCurrentInsuranceFetcher(apiSpec),
    filesUpload: buildFilesUploadPost(apiSpec),
    checkUploaderEnabled: buildUploaderEnabledPost(apiSpec),
    getFilesValidation: buildUploaderFilesValidationGet(apiSpec),
    getExistingFiles: buildUploaderExistingFiles(apiSpec),
    quickquoteById: buildGetQuickQuoteById(apiSpec),
    quoteById: buildGetQuoteById(apiSpec),
    registerUser: registerUser(apiSpec),
    registerFlexyUser: registerFlexyUser(apiSpec),
    registerCustomer: registerCustomer(apiSpec),
    registerUserAuthorize: registerUserAuthorize(apiSpec),
    resendActivationCode: resendActivationCode(apiSpec),
    getFPN: getFPN(apiSpec),
    approveFPN: approveFPN(apiSpec),
    getRenewalForCustomer: buildGetRenewalForCustomer(apiSpec),
    getUpDownForCustomer: buildGetUpDownForCustomer(apiSpec),
    getLiteratureForCustomer: buildGetLiteratureForCustomer(apiSpec),
    postRenewalQuote: buildPostRenewalQuote(apiSpec),
    processRenewalQuote: buildProcessRenewalQuote(apiSpec),
    getQuotesForRenewal: buildGetQuotesForRenewal(apiSpec),
    calculateQuickQuote: buildCalculateQuickQuote(apiSpec),
    calculateRenewalQuickQuoteById: buildCalculateRenewalQuickQuoteById(apiSpec),
    calculateRenewalQuickQuote: buildCalculateRenewalQuickQuote(apiSpec),
    recalculateQuickQuote: buildRecalculateQuickQuote(apiSpec),
    recalculateRenewalQuickQuote: buildRecalculateRenewalQuickQuote(apiSpec),
    getUploadAccessToken: buildGetUploadAccessToken(apiSpec),
    sendFiles: buildSendFiles(apiSpec),
    saveUpload: buildSaveUpload(apiSpec),
    getWebSources: getWebSources(apiSpec),
    completeRegistration: completeRegistration(apiSpec),
    apiSpec,
  };
}

export async function retrieveAndBuildApi() {
  return await fetch(CONFIG_FILE_PATH)
    .then((r) => r.json())
    .then((r) => buildApi(r));
}

export default retrieveAndBuildApi();
