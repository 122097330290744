export const state = {
  upDowns: [],
  literature: {},
  defaultQuote: {},
  quoteDirty: false,
  productId: 0,
  quoteData: {},
  currentQuote: {},
  renewalQuote: {},
  defaultOptions: {},
  quoteRequest: {},
  quoteId: null,
  renewalQuotesList: [],
  renewalQuoteSelectedIndex: -1,
}

export const getters = {
  cover: state => state.cover,
  currentQuote: state => state.currentQuote,
  defaultQuote: state => state.defaultQuote,
  literature: state => state.literature,
  quoteDirty: state => state.quoteDirty,
  quoteId: state => state.quoteId,
  quoteRequest: state => state.quoteRequest,
  renewalProductId: state => state.productId,
  renewalQuickQuote: state => state.quoteData,
  quoteData: state => state.quoteData,
  renewalQuote: state => state.renewalQuote,
  renewalQuotesList: state => state.renewalQuotesList,
  upDowns: state => state.upDowns,
  renewalQuoteSelectedIndex: state => state.renewalQuoteSelectedIndex
}

export const mutations = {
  SET_DEFAULT_QUOTE(state, payload) {
    state.defaultQuote = payload
  },
  SET_QUOTE_DIRTY(state, boolean) {
    state.quoteDirty = boolean
  },
  SET_QUOTE_ID(state, id) {
    state.quoteId = id
  },
  SET_QUOTE_REQUEST(state, payload) {
    state.quoteRequest = payload
  },
  SET_QUOTE_DATA_RENEWAL(state, payload) {
    state.quoteData = payload
  },
  SET_QUOTE_OPTIONS_FOR_MEMBER_RENEWAL(state, {householdId, memberId, productId, productCoverLevel, payload}){
    state.quoteData.householdOptions[householdId].memberOptions[memberId].coverOptions = [
      ...state.quoteData.householdOptions[householdId].memberOptions[memberId]
        .coverOptions.filter(x => x.productId !== productId || x.coverLevel !== productCoverLevel),
      ...payload
    ];
  },
  SET_QUOTE_PRICES_FOR_MEMBER_RENEWAL(state, {householdId, memberId, productId, productCoverLevel, payload}) {
    const memberPrice = state.quoteData.householdPrices[householdId].memberPrices[memberId].coverPrices.find(c => c.productId === productId && c.coverLevel === productCoverLevel);
    memberPrice.priceGrossMonthly = payload.priceGrossMonthly;
    memberPrice.priceGrossMonthlyFull = payload.priceGrossMonthlyFull;
    memberPrice.priceGrossPremium = payload.priceGrossPremium;
    memberPrice.priceGrossYearly = payload.priceGrossYearly;
    memberPrice.priceGrossYearlyFull = payload.priceGrossYearlyFull;
    memberPrice.priceNetPremium = payload.priceNetPremium;
  },

  SET_QUOTE_COVER_LEVEL_FOR_HOUSEHOLD_RENEWAL(state, {householdId, productCoverLevel, membersLevelOfCover}) {
    const household = state.renewalQuote.households[householdId];
    household.coverLevel = productCoverLevel;
    membersLevelOfCover.forEach((element, index) => {
      if(index == 0) {
        const subscriber = household.subscriber.options.find(o => o.optionId == 17);
        subscriber.value = element;
        // Override household LOC based on subscribers LOC
        household.coverLevel = productCoverLevel;
      }
      else {
        const familyMember = household.familyMembers[index - 1].options.find(o => o.optionId == 17);
        familyMember.value = element;
      }
    });



  },
  SET_QUOTE_DEFAULT_OPTIONS_RENEWAL(state, { productId, productCoverLevel, options }) {
    state.defaultOptions.productId = productId;
    state.defaultOptions.productCoverLevel = productCoverLevel;
    state.defaultOptions.options = options;
  },
  SET_CURRENT_QUOTE(state, payload){
    state.currentQuote = payload;
  },
  SET_RENEWAL_QUOTE(state, payload){
    state.renewalQuote = payload;
    state.renewalQuote.productId = payload.productId;
    state.productId = payload.productId;
    for(let i=0; i<payload.households.length; i++){
      state.renewalQuote.households[i].coverLevel = payload.households[i].subscriber.productCoverLevel;
      state.renewalQuote.households[i].householdId = i;
    }
  },
  SET_RENEWAL_UP_DOWNS(state, payload) {
    state.upDowns = payload
  },
  SET_RENEWAL_LITERATURE(state, payload) {
    state.literature = payload
  },
  SET_RENEWAL_QUOTES_LIST(state, payload) {
    state.renewalQuotesList = payload
  },
  SET_RENEWAL_QUOTE_SELECTED_INDEX(state, payload) {
    state.renewalQuoteSelectedIndex = payload
  },
  SET_DISCOUNTS(state, payload) {
    state.renewalQuote.discountDetails = payload.discountDetails;
    state.quoteData.discountDetailsCurrent = payload.discountDetailsCurrent;
  }
}

export const actions = {
  setQuoteDataRenewal({commit}, payload){
    commit('SET_QUOTE_DATA_RENEWAL', payload);
    commit('SET_QUOTE_DIRTY', true);
  },
  setQuoteOptionsForMemberRenewal({commit}, payload){
    commit('SET_QUOTE_OPTIONS_FOR_MEMBER_RENEWAL', payload);
    commit('SET_QUOTE_DIRTY', true);
  },
  setQuotePricesForMemberRenewal({commit}, payload){
    commit('SET_QUOTE_PRICES_FOR_MEMBER_RENEWAL', payload);
    commit('SET_QUOTE_DIRTY', true);
  },
  setQuoteCoverLevelForHouseholdRenewal({commit}, payload){
    commit('SET_QUOTE_COVER_LEVEL_FOR_HOUSEHOLD_RENEWAL', payload);
    commit('SET_QUOTE_DIRTY', true);
  },
  setQuoteDefaultOptionsRenewal({commit}, payload){
    commit('SET_QUOTE_DEFAULT_OPTIONS_RENEWAL', payload);
    commit('SET_QUOTE_DIRTY', false);
  }
}


export const Renewals = {
  state,
  getters,
  mutations,
  actions
}
