import Vue from 'vue'
import Vuex from 'vuex'
import { parseJwt } from '../../utils/auth';

Vue.use(Vuex)

export const Auth = {
    state: {
        accessToken: localStorage.getItem('ACCESS_TOKEN') || '',
        refreshToken: localStorage.getItem('REFRESH_TOKEN') || '',
        status: '',
    },
    getters: {
        accessToken: state => state.accessToken,
        refreshToken: state => state.refreshToken,
        isAuthenticated: state => !!state.accessToken,
        status: state => state.status,
    },
    mutations: {
        setAccessToken(state, token) {
            state.accessToken = token
            state.status = 'success'
        },
        error(state) {
            state.status = 'error'
        },
    },
    actions: {
        Logout({ commit, dispatch }) {
            localStorage.removeItem('REFRESH_TOKEN')
            localStorage.removeItem('ACCESS_TOKEN')
        }
    },
}
