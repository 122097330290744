<template>
  <div class="Navbar">
    <div class="Navbar__Container">
      <img
        v-if="logo"
        class="Navbar__logo Navbar__logo--brand"
        :src="'/img/logo/' + logo"
      />
      <img
        v-else
        @click="redirectToHome"
        class="Navbar__Logo"
        alt="WPA"
        src="../assets/wpa-logo-svg2.svg"
      />
      <template v-if="!isOnlineInviteError">
        <div class="Navbar__Quote">
          <UIButton
            v-if="renewals"
            @click="() => redirectToSecureArea()"
            class="mr-3"
            :text="getTextSecureAreaButton"
            :type="['secondary', 'narrow']"
          />
          <button
            v-if="showRetrieveQuote"
            class="Navbar__QuoteButton"
            @click="$router.push({ name: 'MyProfile' })"
          >
            Retrieve a quote
          </button>
          <div class="Navbar__Contact">
            <div :class="['Navbar__Phone', brandingClassName]">
              If you need help contact us on
              <a
                v-if="isRetail && !renewals"
                :class="brandingClassName"
                :href="'call://01823 625050'"
                >01823 625050</a
              >
              <a
                v-else-if="isRetail && renewals"
                class="color-primary-blue"
                href="call://01823 625230"
                >01823 625230</a
              >
              <a
                v-else-if="isOnlineInvite"
                :class="brandingClassName"
                :href="'call://' + getOnlineInviteTelephoneNumber"
                >{{getOnlineInviteTelephoneNumber}}</a
              >
            </div>
            <div
              v-if="isRetail && !renewals"
              class="Navbar__Info"
              >Lines open Mon-Fri 9am - 5pm</div
            >
            <div
              v-else-if="isRetail && renewals"
              class="Navbar__Info"
              >Lines open Mon-Fri 8am - 7pm, Sat 9am - 12 noon</div
            >
            <div
              v-else-if="isOnlineInvite"
              class="Navbar__Info"
              >Lines open Mon-Fri 8am - 7pm, Sat 9am - 12 noon</div
            >
          </div>
        </div>
        <div class="Navbar__QuoteMobile" v-if="showRetrieveQuote">
          <a
            class="Navbar__QuoteMobileButton"
            @click="$router.push({ name: 'MyProfile' })"
            >Retrieve a quote</a
          >
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import icons from "@/utils/icons";
import loggedUserMethods from "@/mixins/loggedUserMethods";
import UIButton from "../../../commonReausables/UIComponents/UIButton.vue";
import renewalsSetup from "@/mixins/renewalsSetup";

export default Vue.extend({
  name: "T-Navbar",
  mixins: [loggedUserMethods, renewalsSetup],
  components: {
    UIButton,
  },
  created() {},
  data: () => ({
    icons,
  }),
  computed: {
    ...mapGetters(["config", "brandingClassName", "coverType"]),
    logo() {
      return this.$store.state.branding.logoSource;
    },
    renewals() {
      return this.$route.path.includes("renew");
    },
    getTextSecureAreaButton() {
      if (this.isMultiFamily()) {
        return "Access Secure Area";
      } else {
        return "Return to My WPA";
      }
    },
    isOnlineInvite() {
      return this.$store.state.membershipRequest;
    },
    getOnlineInviteTelephoneNumber() {
      return this.$store.state.membershipRequest.telephoneNumber;
    },
    isRetail() {
      if (!this.$store.state.productId && !this.isOnlineInvite) {
        return true;
      }
      return [682, 859, 844, 847, 848, 957, 951, 1072].includes(this.$store.state.productId);
    },
    isOnlineInviteError() {
      return this.$route.params.isOnlineInvite && this.$route.name === "Error";
    },
    showRetrieveQuote() {
      if (this.renewals) return false;
      if (
        this.$store.state.membershipRequest &&
        this.$store.state.membershipRequest.productId
      )
        return false;
      if (this.$store.getters.isInviteUser) return false;
      if (this.$store.getters.isOnlineInvite) return false;
      if (this.isBusiness) return false;
      return true;
    },
    isBusiness() {
      return this.coverType === 4;
    },
  },
  methods: {
    redirectToHome() {
      if (
        this.config &&
        this.config.publicWebsites &&
        this.config.publicWebsites.main
      ) {
        document.location.href = this.config.publicWebsites.main;
      }
    },
    redirectToSecureArea() {
      if (this.isMultiFamily()) {
        this.accessSecureArea();
      } else {
        this.accessNewSecureAreaPersonHomepage();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/theme/variable";
a.Navbar__QuoteMobileButton {
  color: $color-blue-1;
  text-decoration: underline;
  cursor: pointer;
}

.Navbar__logo {
  cursor: pointer;
  &--brand {
    max-height: 50px;
    cursor: auto;
  }
}
</style>
