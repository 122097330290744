<template>
  <div class="Menu">
    <router-link to="/"><img alt="WPA" src="../assets/wpa-logo-svg.svg"></router-link>
    <router-link class="arrow" to="/personal">Personal</router-link>
    <router-link class="arrow" to="/business">Business</router-link>
    <router-link class="arrow" to="/healthcare-providers">Healthcare providers</router-link>
    <router-link class="arrow" to="/intermediaries">Intermediaries</router-link>
    <router-link class="icon search" to="/search">Search</router-link>
    <router-link class="icon contact" to="/contact">Contact us</router-link>
    <router-link class="icon profile" to="/sign-in">Sign in</router-link>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'T-Menu',
})
</script>

<style lang="scss" scoped>
</style>
