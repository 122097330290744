import Vue from 'vue'
import Vuex, { GetterTree, ActionTree, MutationTree } from 'vuex'
import api from '@/api'
import { v4 as guid } from 'uuid';
import { QuotePayload, QuoteHousehold, QuoteMemberOption } from '../quoteType'
import store from '..';
import { MultifamilyQuote } from '../MultifamilyQuote/index';
import router from '../../router/index';
import moment from 'moment'

export
interface GetQuoteState {
  postcode: string | undefined;
  members: MemberData[];
  occupationData: OccupationData;
  defaultCover: QuotePayload | null;
  quote: QuoteObject;
  quotePayload: QuotePayload;
  pendingCustomiseRequests: number;
  loaderForAllCovers: boolean;
  leadData: QuoteLeadData | null;
}

interface PromotionTermsAndConditions {
  termsAndConditionsText: string | null,
  termsAndConditionsUrl: string | null
}

interface QuoteObject {
  productName: string,
  availableBenefits: Array<any>,
  coverDescriptions: CoverDescription[],
  documentsToDownload: [],
  selectedCoverLevelId: number,
  commonWithAllMembersSummary: [],
  householdPrices: HouseholdPrices[],
  householdOptions: HouseholdOption[],
  discountDetails: string | null,
  discountFootnote: string | null,
  promotionTermsAndConditions: PromotionTermsAndConditions | null,
  benefitOptionConditions: BenefitOptionCondition[],
  isSharedResponsibilityOverExcess: boolean,
}

interface QuoteLeadData {
  postcode: string | undefined;
  age: number | undefined;
  firstName: string | undefined;
  surname: string | undefined;
  occupationId: number | undefined;
  occupation: string | undefined;
  smokerVaper: boolean | undefined;
  selfEmployed: boolean | undefined;
  isProfessional: boolean | undefined;
}

interface HouseholdOption {
  memberOptions: MemberOption[];
}

interface MemberOption {
  memberName: string;
  coverOptions: CoverOption[];
}

interface BenefitsPayload {
  memberIndex: number;
  householdIndex: number;
  optionId: number;
  productId: number;
  productCoverLevel: string;
  value: any;
  setPgf: boolean | null | undefined;
  pgfMemberIndex: number | null | undefined;
}

interface CoverOption {
  productId: number;
  coverLevel: string;
  optionId: number;
  value: string;
}

export
interface MemberData {
  age: any
  smoker: any;
  name: any;
  surname: any;
  id?: any;
  desc?: any;
  allowMoratorium: boolean;
  underwritingTypeId: any;
  personalDetailsFilled: boolean;
}

interface OccupationData {
  selfEmployed: boolean | undefined;
  occupation: string | undefined;
  memberOfProfession: boolean | undefined;
}

interface UpdateMemberData {
  memberIndex: number;
  memberData: MemberData
}

interface HouseholdPrices {
  memberPrices: MemberPrices[];
}

interface MemberPrices {
  coverPrices: CoverPrice[];
  memberName: string;
}

interface CoverPrice {
  coverLevel: string;
  priceNetPremium: number;
  priceGrossPremium: number;
  priceGrossYearly: number;
  priceGrossMonthly: number;
  priceGrossYearlyFull: number;
  priceGrossMonthlyFull: number;
  productId: number;
}

interface CustomiseHousehold {
  members: CustomiseMember[];
}

interface CustomiseMember {
  priceNetPremium: number;
  priceGrossPremium: number;
  priceGrossYearly: number;
  priceGrossMonthly: number;
  priceGrossYearlyFull: number;
  priceGrossMonthlyFull: number;
  options: QuoteMemberOption[];
}

interface CoverDescription {
  coverName: string;
  id: number;
  price: number;
  priceFull: number;
  shortDescription: string;
  unit: string;
  benefits: string[]
}
interface CustomiseHouseholdByIndex {
  households: CustomiseHousehold[];
  index: number;
}

interface BenefitOptionCondition {
  cover: string,
  optionId: number,
  value: string,
  effects: BenefitOptionConditionEffect[]
}

interface BenefitOptionConditionEffect {
  optionId: number,
  values: BenefitOptionConditionValues[],
  currentValueCondition: string
}

interface BenefitOptionConditionValues {
  value: string,
  enabled: boolean,
  selected: boolean
}

Vue.use(Vuex)

export const state: GetQuoteState = {
  postcode: undefined,
  occupationData: {
    selfEmployed: undefined,
    occupation: undefined,
    memberOfProfession: undefined,
  },
  members: [],
  defaultCover: null,
  quote: {
    productName: '',
    availableBenefits: [],
    coverDescriptions: [],
    documentsToDownload: [],
    selectedCoverLevelId: 0,
    commonWithAllMembersSummary: [],
    householdPrices: [],
    householdOptions: [],
    discountDetails: null,
    discountFootnote: null,
    promotionTermsAndConditions: null,
    benefitOptionConditions: [],
    isSharedResponsibilityOverExcess: false,
  },
  quotePayload: {
    quoteId: 0,
    productId: 0,
    startDateOfPolicy: '',
    expirationDate: '',
    homePostcode: '',
    households: [],
    externalLinkId: '',
    affinityId: null,
    applicationId: null,
    sponsoredId: null,
    costcoMembershipTypeId: null,
    promotionId: null,
    occupationId: 0,
    occupation: undefined,
    isProfessional: false,
    isSelfEmployed: false,
    priceGrossMonthly: 0,
    priceGrossMonthlyFull: 0,
    priceGrossYearly: 0,
    priceGrossYearlyFull: 0,
    priceNetPremium: 0,
    priceGrossPremium: 0,
    options: [],
    productCoverLevel: null,
    isCurrentlyInsured: null,
  },
  pendingCustomiseRequests: 0,
  loaderForAllCovers: false,
  leadData: null
}


export const getters: GetterTree<GetQuoteState, any> = {
  leadData: (state: GetQuoteState) => state.leadData,
  postcode: (state: GetQuoteState) => state.postcode,
  members: (state: GetQuoteState) => state.members,
  occupation: (state: GetQuoteState) => state.occupationData.occupation,
  selfEmployed: (state: GetQuoteState) => state.occupationData.selfEmployed,
  memberOfProfession: (state: GetQuoteState) => state.occupationData.memberOfProfession,
  membersAmount: (state: GetQuoteState) => state.members.length,
  quote: (state: GetQuoteState) => state.quote,
  // householdsOptions is indirectly set by `fetchQuickQuote`
  householdsOptions: (state: GetQuoteState) => state.quote.householdOptions,
  quotePayload: (state: GetQuoteState) => state.quotePayload,
  customiseQuoteLoader: (state: GetQuoteState) => state.pendingCustomiseRequests > 0,
  quotePayloadWithoutMemberIds: (state: GetQuoteState) => stripMembersIds(state.quotePayload),
  fullProductName: (state: GetQuoteState) => state.quote.productName,
  quoteCoverLevelsLength: (state: GetQuoteState) => state.quote.coverDescriptions.length,
  quotePayloadsForAllLevels: (state: GetQuoteState) => {
    return state.quote.coverDescriptions.map(cover => {
      return {
        ...state.quotePayload,
        productCoverLevel: cover.coverName,
        productId: cover.id,
        households: state.quotePayload.households.map((household, index) => {
          return {
            ...household,
            subscriber: {
              ...household.subscriber,
              options: state.quote.householdOptions[index].memberOptions[0].coverOptions.filter(o => o.coverLevel === cover.coverName && o.productId === cover.id).map(op => ({optionId: op.optionId, value: op.value}))
            },
            familyMembers: household.familyMembers.map((member, i) => {
              return {
                ...member,
                options: state.quote.householdOptions[index].memberOptions[i + 1].coverOptions.filter(o => o.coverLevel === cover.coverName && o.productId === cover.id).map(op => ({optionId: op.optionId, value: op.value}))
              }
            })
          }
        })
      }
    })
  },
  loaderForAllCovers: (state: GetQuoteState) => state.loaderForAllCovers,
  selectableOptionIds: (state: GetQuoteState) =>
    state.quote.availableBenefits.flatMap(ab => ab.subBenefits.map(sb => sb.id).filter(id => id > 0))
}

export const mutations: MutationTree<GetQuoteState> = {

  // common with global store
  setRecommendedProduct (state, data) {
    state.quotePayload.productId = data.productId;
    state.quotePayload.productCoverLevel = data.coverLevel;
  },
  setProductId(state, value) {
    state.quotePayload.productId = value
  },
  setProductCoverLevel(state, value) {
    state.quotePayload.productCoverLevel = value
  },

  'GET_QUOTE_ADD_MEMBER'(state: GetQuoteState) {
    if (!state.members.length && state.leadData) {
      state.members.push({
        age: state.leadData.age,
        smoker: state.leadData.smokerVaper ? 1 : 0,
        name: state.leadData.firstName,
        surname: null, //state.leadData.surname, - removed because splitting name was reverted
        id:  guid(),
        desc: {},
        allowMoratorium: true,
        underwritingTypeId: null,
        personalDetailsFilled: false,
      })
    }
    else
      state.members.push({
        age: undefined,
        smoker: undefined,
        name: undefined,
        surname: undefined,
        id:  guid(),
        desc: {},
        allowMoratorium: true,
        underwritingTypeId: null,
        personalDetailsFilled: false,
      })
  },

  'GET_QUOTE_REMOVE_MEMBER'(state: GetQuoteState, index: number) {
    state.members.splice(index, 1);
  },

  'GET_QUOTE_UPDATE_MEMBER'(state: GetQuoteState, data: UpdateMemberData) {
    state.members[data.memberIndex].age = data.memberData.age;
    state.members[data.memberIndex].smoker = data.memberData.smoker;
    state.members[data.memberIndex].name = data.memberData.name;
    state.members[data.memberIndex].surname = data.memberData.surname;
  },

  'GET_QUOTE_SET_POSTCODE'(state: GetQuoteState, postcode: string) {
    state.postcode = postcode;
  },

  'GET_QUOTE_CLEAR_POSTCODE'(state: GetQuoteState) {
    state.postcode = undefined;
  },

  'GET_QUOTE_SET_SELECTED_PROMOTION_ID' (state: GetQuoteState, costcoMembershipTypeId: number) {
    state.quotePayload.costcoMembershipTypeId = costcoMembershipTypeId;
  },

  'GET_QUOTE_SET_EMPLOYMENT_TYPE' (state: GetQuoteState, selfEmployed) {
    state.occupationData.selfEmployed = selfEmployed;
    state.quotePayload.isSelfEmployed = selfEmployed;
  },
  'GET_QUOTE_SET_AFFINITY_ID' (state: GetQuoteState, affinityId) {
    state.quotePayload.affinityId = affinityId;
  },
  'GET_QUOTE_SET_SPONSORED_ID' (state: GetQuoteState, sponsoredId) {
    state.quotePayload.sponsoredId = sponsoredId;
  },
  'GET_QUOTE_SET_EXTERNAL_LINK_ID' (state: GetQuoteState, externalLinkId) {
    state.quotePayload.externalLinkId = externalLinkId;
  },

  'GET_QUOTE_SET_MEMBER_OF_PROFESSION' (state: GetQuoteState, memberOfProfession) {
    state.occupationData.memberOfProfession = memberOfProfession;
    state.quotePayload.isProfessional = memberOfProfession
  },

  'GET_QUOTE_SET_OCCUPATION' (state: GetQuoteState, occupation) {
    state.occupationData.occupation = occupation;
    state.quotePayload.occupationId = occupation.occupationId ? occupation.occupationId : null;
    state.quotePayload.occupation = occupation.description;
  },

  'GET_QUOTE_CLEAR_OCCUPATION' (state: GetQuoteState) {
    state.occupationData.occupation = undefined;
    state.quotePayload.occupationId = undefined;
    state.quotePayload.occupation = undefined;
  },

  'CLEAR_DATA' (state: GetQuoteState) {
    state.postcode = undefined;

    state.occupationData.selfEmployed = undefined;
    state.occupationData.occupation = undefined;
    state.occupationData.memberOfProfession = undefined;

    state.members = [];

    state.quote.productName = '';
    state.quote.availableBenefits = [];
    state.quote.coverDescriptions = [];
    state.quote.documentsToDownload = [];
    state.quote.selectedCoverLevelId = 0;
    state.quote.commonWithAllMembersSummary = [];
    state.quote.householdPrices = [];
    state.quote.householdOptions = [];

    state.quotePayload.quoteId = 0;
    state.quotePayload.productId = 0;
    state.quotePayload.startDateOfPolicy = new Date().toISOString().slice(0, 10);
    state.quotePayload.homePostcode = '';
    state.quotePayload.households = [];
    state.quotePayload.affinityId = null;
    state.quotePayload.sponsoredId = null;
    state.quotePayload.externalLinkId = '';
    state.quotePayload.costcoMembershipTypeId = null;
    state.quotePayload.occupationId = 0;
    state.quotePayload.occupation = undefined;
    state.quotePayload.isProfessional = false;
    state.quotePayload.isSelfEmployed = false;
    state.quotePayload.priceGrossMonthly = 0;
    state.quotePayload.priceGrossMonthlyFull = 0;
    state.quotePayload.priceGrossYearly = 0;
    state.quotePayload.priceGrossYearlyFull = 0;
    state.quotePayload.priceNetPremium = 0;
    state.quotePayload.priceGrossPremium = 0;
    state.quotePayload.options = [];
    state.quotePayload.productCoverLevel = null;
    state.quotePayload.isCurrentlyInsured = null;

    state.pendingCustomiseRequests = 0;
    state.loaderForAllCovers = false;
    state.leadData = null;
  },

  'SET_QUICKQUOTE' (state: GetQuoteState, { quote, selectableOptions}) {
    state.quote = quote;
    function copyOptions(newOptions, oldOptions) {
      return newOptions
        .filter(op => op.productId === state.quotePayload.productId && op.coverLevel === state.quotePayload.productCoverLevel)
        .map(op => selectableOptions.includes(op.optionId) && oldOptions.find(o => o.optionId == op.optionId) || ({optionId: op.optionId, value: op.value}));
    }

    state.quotePayload.promotionId = quote.promotionId;
    state.quotePayload.options = copyOptions(quote.groupOptions, state.quotePayload.options);
    state.quote.householdOptions.forEach((household, index) => {
      const payloadMembers = [state.quotePayload.households[index].subscriber, ...state.quotePayload.households[index].familyMembers];
      household.memberOptions.forEach((member, i) => {
        payloadMembers[i].options = copyOptions(member.coverOptions, payloadMembers[i].options);
      })
    })
  },

  'SET_MEMBER_DESCRIPTION' (state: GetQuoteState, data) {
    state.members[data.index].desc = Object.assign(state.members[data.index].desc, data.payload)
  },

  'SET_UNDERWRITING_TYPE_ID' (state: GetQuoteState, payload: { memberId, underwritingTypeId }) {
    const chosenMember = state.members.filter(member => {
      return member.id === payload.memberId
    })
    chosenMember[0]['underwritingTypeId'] = payload.underwritingTypeId
  },
  'PERSONAL_DETAILS_FILLED' (state: GetQuoteState, index: string) {
    state.members[index].personalDetailsFilled = true
  },

  'SET_OPTIONAL_BENEFITS_FOR_ALL' (state: GetQuoteState, data: BenefitsPayload) {
    state.quote.householdOptions.forEach(household => {
      household.memberOptions.forEach(memberOption => {
        const coverOption = memberOption.coverOptions.find(option => option.optionId === data.optionId && option.productId === data.productId && option.coverLevel === data.productCoverLevel)
        if (coverOption) {
          coverOption.value = data.value
        }

        if (!data.productCoverLevel) {
          memberOption.coverOptions.forEach(option => {
            if (option.optionId === data.optionId) {
              option.value = data.value
            }
          })
        }
      })
    })

    state.quotePayload.households.forEach(household => {
      const subscriber = household.subscriber.options.find(option => option.optionId === data.optionId && state.quotePayload.productId === data.productId && (data.productCoverLevel ? data.productCoverLevel === state.quotePayload.productCoverLevel : true))

      if (subscriber) {
        subscriber.value = data.value
      }
    })

    state.quotePayload.households.forEach(household => {
      household.familyMembers.forEach(memberOption => {
        const member = memberOption.options.find(option => option.optionId === data.optionId && state.quotePayload.productId === data.productId && (data.productCoverLevel ? data.productCoverLevel === state.quotePayload.productCoverLevel : true))
        if (member) {
          member.value = data.value
        }
      })
    })
  },
  'SET_OPTIONAL_BENEFITS_PER_HOUSEHOLD' (state: GetQuoteState, data: BenefitsPayload) {
    state.quote.householdOptions[data.householdIndex].memberOptions.forEach(memberOption => {
      const coverOption = memberOption.coverOptions.find(option => option.optionId === data.optionId && option.productId === data.productId && option.coverLevel === data.productCoverLevel)
      if (coverOption) {
        coverOption.value = data.value
      }
    })

    const subscriber = state.quotePayload.households[data.householdIndex].subscriber.options
        .find(option => option.optionId === data.optionId && state.quotePayload.productId === data.productId && data.productCoverLevel === state.quotePayload.productCoverLevel)

    if (subscriber) {
      subscriber.value = data.value
    }

    state.quotePayload.households[data.householdIndex].familyMembers.forEach(memberOption => {
      const coverOption = memberOption.options.find(option => option.optionId === data.optionId && state.quotePayload.productId === data.productId && data.productCoverLevel === state.quotePayload.productCoverLevel)
      if (coverOption) {
        coverOption.value = data.value
      }
    })

  },
  'SET_ALL_BENEFITS_PER_HOUSEHOLD' (state: GetQuoteState, data) {
    const chosenHousehold = MultifamilyQuote.state.households.find(h => h.id === data.householdId)
    if (chosenHousehold) {
      const chosenHouseholdId = MultifamilyQuote.state.households.indexOf(chosenHousehold)

      state.quote.householdOptions[chosenHouseholdId].memberOptions.forEach((member, i) => {
          if (i === 0) {
            state.quotePayload.households[chosenHouseholdId].subscriber.options = member.coverOptions.filter(op => op.productId === state.quotePayload.productId && op.coverLevel === state.quotePayload.productCoverLevel).map(op => ({optionId: op.optionId, value: op.value}))
          } else {
            if (state.quotePayload.households[chosenHouseholdId].familyMembers.length) {
              state.quotePayload.households[chosenHouseholdId].familyMembers[i - 1].options = member.coverOptions.filter(op => op.productId === state.quotePayload.productId && op.coverLevel === state.quotePayload.productCoverLevel).map(op => ({optionId: op.optionId, value: op.value}))
            }
          }
        })

    }
  },
  'SET_OPTIONAL_BENEFITS_PER_MEMBER_MULTIFAMILY' (state: GetQuoteState, data: BenefitsPayload) {
    const coverOption = state.quote.householdOptions[data.householdIndex].memberOptions[data.memberIndex].coverOptions
                      .find(option => option.optionId === data.optionId && option.productId === data.productId && option.coverLevel === data.productCoverLevel)
    if (coverOption) {
      coverOption.value = data.value
    }

    if (data.memberIndex === 0) {
      const subscriber = state.quotePayload.households[data.householdIndex].subscriber.options
                        .find(option => option.optionId === data.optionId && state.quotePayload.productId === data.productId && data.productCoverLevel === state.quotePayload.productCoverLevel)
      if (subscriber) {
        subscriber.value = data.value
      }
    } else {
      const member = state.quotePayload.households[data.householdIndex].familyMembers[data.memberIndex - 1].options
                  .find(option => option.optionId === data.optionId && state.quotePayload.productId === data.productId && data.productCoverLevel === state.quotePayload.productCoverLevel)
      if (member) {
      member.value = data.value
      }
    }
  },
  'SET_OPTIONAL_BENEFITS_PER_MEMBER_SINGLEFAMILY' (state: GetQuoteState, data: BenefitsPayload) {
    const coverOption = state.quote.householdOptions[0].memberOptions[data.memberIndex].coverOptions
                      .find(option => option.optionId === data.optionId && option.productId === data.productId && (data.productCoverLevel ? option.coverLevel === data.productCoverLevel : true))
    if (coverOption) {
      coverOption.value = data.value
    }

    if (!data.productCoverLevel) {
      state.quote.householdOptions[0].memberOptions[data.memberIndex].coverOptions.forEach(option => {
        if (option.optionId === data.optionId) {
          option.value = data.value
        }
      })
    }

    if (data.memberIndex === 0) {
      const subscriber = state.quotePayload.households[0].subscriber.options
            .find(option => option.optionId === data.optionId && state.quotePayload.productId === data.productId && (data.productCoverLevel ? data.productCoverLevel === state.quotePayload.productCoverLevel : true))
      if (subscriber) {
      subscriber.value = data.value
      }
    } else {
      const member = state.quotePayload.households[0].familyMembers[data.memberIndex - 1].options
            .find(option => option.optionId === data.optionId && state.quotePayload.productId === data.productId && (data.productCoverLevel ? data.productCoverLevel === state.quotePayload.productCoverLevel : true))
      if (member) {
      member.value = data.value
      }
    }
  },
  'SET_HOUSEHOLDS_IN_QUOTE_PAYLOAD' (state: GetQuoteState, households: QuoteHousehold[]) {
    state.quotePayload.households = households
    state.quotePayload.homePostcode = households[0].postcode
  },

  'SET_INITIAL_OPTIONS_PER_MEMBER' (state: GetQuoteState) {
    state.quote.householdOptions.forEach((household, index) => {
      household.memberOptions.forEach((member, i) => {
        if (i === 0) {
          state.quotePayload.households[index].subscriber.options = member.coverOptions.filter(op => op.productId === state.quotePayload.productId && op.coverLevel === state.quotePayload.productCoverLevel).map(op => ({optionId: op.optionId, value: op.value}))
        } else {
          if (state.quotePayload.households[index].familyMembers.length) {
            state.quotePayload.households[index].familyMembers[i - 1].options = member.coverOptions.filter(op => op.productId === state.quotePayload.productId && op.coverLevel === state.quotePayload.productCoverLevel).map(op => ({optionId: op.optionId, value: op.value}))
          }
        }
      })
    })
  },

  'SET_QUICKQUOTE_CUSTOMISE' (state: GetQuoteState, households) {
    state.quote.householdPrices.forEach((household, index) => {
      household.memberPrices.forEach((memberPrice, i) => {
        const member = memberPrice.coverPrices.find(coverPrice => coverPrice.productId === state.quotePayload.productId && coverPrice.coverLevel === state.quotePayload.productCoverLevel)

        if (member) {
          member.priceGrossMonthly = households[index].members[i].priceGrossMonthly;
          member.priceGrossMonthlyFull = households[index].members[i].priceGrossMonthlyFull;
          member.priceGrossYearly = households[index].members[i].priceGrossYearly;
          member.priceGrossYearlyFull = households[index].members[i].priceGrossYearlyFull;
          member.priceNetPremium = households[index].members[i].priceNetPremium;
          member.priceGrossPremium = households[index].members[i].priceGrossPremium;
        }
      })
    })

    state.quote.householdOptions.forEach((household, index) => {
      household.memberOptions.forEach((member, i) => {
        if (i === 0) {
          state.quotePayload.households[index].subscriber.options = households[index].members[i].options.map(op => ({optionId: op.id, value: op.value}));
        } else {
          if (state.quotePayload.households[index].familyMembers.length) {
            state.quotePayload.households[index].familyMembers[i - 1].options = households[index].members[i].options.map(op => ({optionId: op.id, value: op.value}));
          }
        }
      })
    })
  },
  'SET_QUICKQUOTE_CUSTOMISE_BY_INDEX' (state: GetQuoteState, data: CustomiseHouseholdByIndex ) {
    state.quote.householdPrices.forEach((household, index) => {
      household.memberPrices.forEach((memberPrice, i) => {
        memberPrice.coverPrices[data.index].priceGrossMonthly = data.households[index].members[i].priceGrossMonthly;
        memberPrice.coverPrices[data.index].priceGrossMonthlyFull = data.households[index].members[i].priceGrossMonthlyFull;
        memberPrice.coverPrices[data.index].priceGrossYearly = data.households[index].members[i].priceGrossYearly;
        memberPrice.coverPrices[data.index].priceGrossYearlyFull = data.households[index].members[i].priceGrossYearlyFull;
        memberPrice.coverPrices[data.index].priceNetPremium = data.households[index].members[i].priceNetPremium;
        memberPrice.coverPrices[data.index].priceGrossPremium = data.households[index].members[i].priceGrossPremium;
      })
    });
  },
  'INCREASE_PENDINGS_REQUEST' (state: GetQuoteState) {
    state.pendingCustomiseRequests += 1;
  },
  'DECREASE_PENDINGS_REQUEST' (state: GetQuoteState) {
    state.pendingCustomiseRequests -= 1;
  },
  'SET_AS_DEFAULT_FOR_HOUSEHOLD' (state: GetQuoteState, {memberIndex, householdIndex, selectableOptions}) {


    let memberOptions: QuoteMemberOption[]
    if (memberIndex === 0) {
      memberOptions = state.quotePayload.households[householdIndex].subscriber.options.filter(x => selectableOptions.some(oId => oId === x.optionId));
    } else {
      memberOptions = state.quotePayload.households[householdIndex].familyMembers[memberIndex - 1].options.filter(x => selectableOptions.some(oId => oId === x.optionId));
    }

    state.quotePayload.households[householdIndex].subscriber.options.filter(x => selectableOptions.some(oId => oId === x.optionId)).forEach(o => {
      const option = memberOptions.find(option => option.optionId === o.optionId)

      if (option) {
        o.value = option.value
      }
    })

    state.quotePayload.households[householdIndex].familyMembers.forEach(member => {
      member.options.filter(x => selectableOptions.some(oId => oId === x.optionId)).forEach(o => {
        const option = memberOptions.find(option => option.optionId === o.optionId)
        if (option) {
          o.value = option.value
        }
      })
    })
  },
  'SET_AS_DEFAULT_FOR_HOUSEHOLDS' (state: GetQuoteState, {householdIndex, selectableOptions}) {
    const currentHousehold = MultifamilyQuote.state.households[householdIndex];

    for (let index = 0; index < MultifamilyQuote.state.households.length; index++) {
      if (index != householdIndex) {
        const household = MultifamilyQuote.state.households[index];
        household.productCoverLevel = currentHousehold.productCoverLevel;
      }
    }

    const memberOptions = state.quotePayload.households[householdIndex].subscriber.options.filter(x => selectableOptions.some(oId => oId === x.optionId));
    state.quotePayload.households = state.quotePayload.households.map((household, i) => {
      if (i != householdIndex) {
        return {
          ...household,
          subscriber: {
            ...household.subscriber,
            options: household.subscriber.options.map(o => {
                const option = memberOptions.find(x => x.optionId === o.optionId);
                if (option) {
                  return {
                    optionId: option.optionId,
                    value: option.value
                  }
                }
                return {
                  optionId: o.optionId,
                  value: o.value
                }
              })
          },
          familyMembers: household.familyMembers.map((familyMember, j) => ({
            ...familyMember,
            options: familyMember.options.map(o => {
              const option = memberOptions.find(x => x.optionId === o.optionId);
                if (option) {
                  return {
                    optionId: option.optionId,
                    value: option.value
                  }
                }
              return {
                optionId: o.optionId,
                value: o.value
              }
            })

          }))
        };
      }

      return household;
    })
  },
  'RESET_HOUSEHOLD_TO_DEFAULT' (state: GetQuoteState, {householdIndex}) {
    if (state.defaultCover) {
      const {households, ...defaultCover} = state.defaultCover;
      state.quotePayload = Object.assign({}, state.quotePayload, defaultCover);
      state.quotePayload.households = households.map((household, i) => {
        if (i == householdIndex) {
          return {
            ...household,
            subscriber: {
              ...household.subscriber,
              options: household.subscriber.options.map(o => {
                  return {
                    optionId: o.optionId,
                    value: o.value
                  }
                })
            },
            familyMembers: household.familyMembers.map((familyMember, j) => ({
              ...familyMember,
              options: familyMember.options.map(o => {
                return {
                  optionId: o.optionId,
                  value: o.value
                }
              })

            }))
          };
        }
        return state.quotePayload.households[i];
      })
    }
  },
  'SET_QUOTE_PAYLOAD' (state: GetQuoteState, payload) {
    // state.quotePayload = payload
    // can't replace quotePayload object, because ref is used in global store
    state.quotePayload.quoteId = payload.id;
    state.quotePayload.productId = payload.productId;
    state.quotePayload.startDateOfPolicy = payload.startDateOfPolicy;
    state.quotePayload.expirationDate = payload.expirationDate;
    state.quotePayload.homePostcode = payload.homePostcode;
    state.quotePayload.households = payload.households;
    state.quotePayload.affinityId = payload.affinityId;
    state.quotePayload.applicationId = payload.applicationId;
    state.quotePayload.sponsoredId = payload.sponsoredId;
    state.quotePayload.externalLinkId = payload.externalLinkId;
    state.quotePayload.costcoMembershipTypeId = payload.costcoMembershipTypeId;
    state.quotePayload.occupationId = payload.occupationId;
    state.quotePayload.occupation = payload.occupation;
    state.quotePayload.isProfessional = payload.isProfessional;
    state.quotePayload.isSelfEmployed = payload.isSelfEmployed;
    state.quotePayload.priceGrossMonthly = payload.priceGrossMonthly;
    state.quotePayload.priceGrossMonthlyFull = payload.priceGrossMonthlyFull;
    state.quotePayload.priceGrossYearly = payload.priceGrossYearly;
    state.quotePayload.priceGrossYearlyFull = payload.priceGrossYearlyFull;
    state.quotePayload.priceNetPremium = payload.priceNetPremium;
    state.quotePayload.priceGrossPremium = payload.priceGrossPremium;
    state.quotePayload.options = payload.options;
    state.quotePayload.productCoverLevel = payload.productCoverLevel;
    state.quotePayload.isCurrentlyInsured = payload.isCurrentlyInsured;
  },

  'SET_QUOTE_BY_ID' (state: GetQuoteState, payload) {
    state.members = []
    // state.quotePayload = payload
    // can't replace quotePayload object, because ref is used in global store
    state.quotePayload.quoteId = payload.id;
    state.quotePayload.expirationDate = payload.expirationDate;
    state.quotePayload.productId = payload.productId;
    state.quotePayload.startDateOfPolicy = payload.startDateOfPolicy;
    state.quotePayload.homePostcode = payload.homePostcode;
    state.quotePayload.households = payload.households;
    state.quotePayload.affinityId = payload.affinityId;
    state.quotePayload.applicationId = payload.applicationId;
    state.quotePayload.sponsoredId = payload.sponsoredId;
    state.quotePayload.externalLinkId = payload.externalLinkId;
    state.quotePayload.costcoMembershipTypeId = payload.costcoMembershipTypeId;
    state.quotePayload.occupationId = payload.occupationId;
    state.quotePayload.occupation = payload.occupation;
    state.quotePayload.isProfessional = payload.isProfessional;
    state.quotePayload.isSelfEmployed = payload.isSelfEmployed;
    state.quotePayload.priceGrossMonthly = payload.priceGrossMonthly;
    state.quotePayload.priceGrossMonthlyFull = payload.priceGrossMonthlyFull;
    state.quotePayload.priceGrossYearly = payload.priceGrossYearly;
    state.quotePayload.priceGrossYearlyFull = payload.priceGrossYearlyFull;
    state.quotePayload.priceNetPremium = payload.priceNetPremium;
    state.quotePayload.priceGrossPremium = payload.priceGrossPremium;
    state.quotePayload.options = payload.options;
    state.quotePayload.isCurrentlyInsured = payload.isCurrentlyInsured;

    const householdProductCoverLevel = payload.households[0].subscriber.productCoverLevel;
    state.quotePayload.productCoverLevel = householdProductCoverLevel;

    store.state.productId = payload.productId
    store.state.productCoverLevel = householdProductCoverLevel;

    if (payload.households.length > 1) {
      store.state.isMultiFamily = true
      MultifamilyQuote.state.households = []
      payload.households.forEach((household, index) => {
        MultifamilyQuote.state.households.push({
          id: household.subscriber.id + '',
          productCoverLevel: household.subscriber.productCoverLevel,
          address: household.postcode,
          name: household.name,
          members: [],
          productId: payload.productId
        });

        MultifamilyQuote.state.households[index].members.push({
          age: payload.households[index].subscriber.age,
          name: payload.households[index].subscriber.memberName,
          surname: payload.households[index].subscriber.memberSurname,
          smoker: payload.households[index].subscriber.smokerVaper,
          personalDetailsFilled: false
        })

        household.familyMembers.forEach(member => {
          MultifamilyQuote.state.households[index].members.push({
            age: member.age,
            name: member.memberName,
            surname: member.memberSurname,
            smoker: member.smokerVaper,
            personalDetailsFilled: false
          })
        })
      })
    } else {
      state.members = []
      store.state.isMultiFamily = false
      state.postcode = payload.households[0].postcode
      state.members.push({
        age: payload.households[0].subscriber.age,
        name: payload.households[0].subscriber.memberName,
        surname: payload.households[0].subscriber.memberSurname,
        smoker: payload.households[0].subscriber.smokerVaper,
        allowMoratorium: true,
        underwritingTypeId: 0,
        personalDetailsFilled: false
      })

      payload.households[0].familyMembers.forEach(member => {
        state.members.push({
          age: member.age,
          name: member.memberName,
          surname: member.memberSurname,
          smoker: member.smokerVaper,
          allowMoratorium: true,
          underwritingTypeId: 0,
          personalDetailsFilled: false
        })
      })
    }
  },
  'CLEAR_QUOTE_COVER_LEVEL'(state: GetQuoteState) {
    state.quotePayload.households.forEach(household => {
      household.subscriber.productCoverLevel = undefined;
      household.familyMembers.forEach(member => {
        member.productCoverLevel = undefined;
      });
    });
  },

  'SET_QUICKQUOTE_BY_ID' (state: GetQuoteState, quote) {
    state.quote = quote

    const coverPrice = quote.householdPrices[0].memberPrices[0].coverPrices.find(cp => cp.priceGrossMonthly === state.quotePayload.households[0].subscriber.priceGrossMonthly && cp.productId === state.quotePayload.productId)

    store.state.productCoverLevel = coverPrice ? coverPrice.coverLevel : quote.householdPrices[0].memberPrices[0].coverPrices[0].coverLevel
    state.quotePayload.productCoverLevel = coverPrice ? coverPrice.coverLevel : quote.householdPrices[0].memberPrices[0].coverPrices[0].coverLevel
  },

  'SET_LOADER_FOR_ALL_COVERS' (state: GetQuoteState, boolean) {
    state.loaderForAllCovers = boolean
  },

  'SET_POLICY_START_DATE' (state: GetQuoteState, date) {
    state.quotePayload.startDateOfPolicy = date
    state.quotePayload.expirationDate = moment(date).add(14, "days").format("YYYY-MM-DD")
  },

  'SET_IS_CURRENTLY_INSURED' (state: GetQuoteState, isCurrentlyInsured) {
    state.quotePayload.isCurrentlyInsured = isCurrentlyInsured;
  },

  'REPLACE_OPTIONS' (state: GetQuoteState, data) {
    // copy options in quote.householdOptions for changed cover level
    state.quote.householdOptions = state.quote.householdOptions.map((householdOption, i) => ({
      memberOptions: householdOption.memberOptions.map((memberOption, j) => ({
        ...memberOption,
        coverOptions: [
          ...memberOption.coverOptions.filter(o => !(o.coverLevel === data.productCoverLevel && o.productId === data.productId)),
          ...data.households[i].members[j].options.map(o => ({
            productId: data.productId,
            coverLevel: data.productCoverLevel,
            optionId: o.id,
            value: o.value
          }))
        ]
      }))
    }))
    // copy options in quotePayload for changed cover level
    if (store.state.isMultiFamily) {
      // if it's multi family, cover levels are stored in MultifamilyQuote.state.households or global store
      state.quotePayload.households = state.quotePayload.households.map((household, i) => {
        const productCoverLevel = MultifamilyQuote.state.households[i].productCoverLevel !== undefined ? MultifamilyQuote.state.households[i].productCoverLevel : store.state.productCoverLevel;
        const productId = MultifamilyQuote.state.households[i].productId !== undefined ? MultifamilyQuote.state.households[i].productId : store.state.productId;
        return {
          ...household,
          subscriber: {
            ...household.subscriber,
            options: productId === data.productId && productCoverLevel === data.productCoverLevel ? data.households[i].members[0].options.map(o => ({
              optionId: o.id,
              value: o.value
            })) : household.subscriber.options
          },
          familyMembers: household.familyMembers.map((familyMember, j) => ({
            ...familyMember,
            options: productId === data.productId && productCoverLevel === data.productCoverLevel ? data.households[i].members[j + 1].options.map(o => ({
              optionId: o.id,
              value: o.value
            })) : familyMember.options
          }))
        };
      })
    }
    else {
      // else cover levels are stored in global store
      const productCoverLevel = store.state.productCoverLevel;
      const productId = store.state.productId;

      if (productId === data.productId && productCoverLevel === data.productCoverLevel) {
        state.quotePayload.households = state.quotePayload.households.map((household, i) => {
          return {
            ...household,
            subscriber: {
              ...household.subscriber,
              options: data.households[i].members[0].options.map(o => ({
                optionId: o.id,
                value: o.value
              }))
            },
            familyMembers: household.familyMembers.map((familyMember, j) => ({
              ...familyMember,
              options: data.households[i].members[j + 1].options.map(o => ({
                optionId: o.id,
                value: o.value
              }))
            }))
          };
        })
      }
    }
  },

  'SET_DEFAULT_COVER' (state: GetQuoteState) {
    state.defaultCover = Object.assign({}, state.quotePayload);
    state.defaultCover.households = state.quotePayload.households.map((household, i) => {
        return {
          ...household,
          subscriber: {
            ...household.subscriber,
            options: household.subscriber.options.map(o => {
                return {
                  optionId: o.optionId,
                  value: o.value
                }
              })
          },
          familyMembers: household.familyMembers.map((familyMember, j) => ({
            ...familyMember,
            options: familyMember.options.map(o => {
              return {
                optionId: o.optionId,
                value: o.value
              }
            })

          }))
        };
    })
  },

  'SET_QUOTE_LEAD_DATA' (state: GetQuoteState, leadData) {
    state.quotePayload.isSelfEmployed = leadData.selfEmployed,
    state.quotePayload.isProfessional = leadData.isProfessional,
    state.leadData = leadData;
    state.occupationData.selfEmployed = leadData.selfEmployed;
  },

  'SET_QUICKQUOTE_DISCOUNTS_INFO' (state: GetQuoteState, discounts) {
    state.quote.discountDetails = discounts.discountDetails;
    state.quote.discountFootnote = discounts.discountFootnote;
    state.quote.promotionTermsAndConditions = discounts.termsAndConditions;
    state.quotePayload.promotionId = discounts.promotionId;
  },

  'UPDATE_PGF_OPTIONS' (state, selectableOptions) {
    for (const household of state.quotePayload.households) {
      const members = [household.subscriber, ...household.familyMembers];
      const pgfIndex = members.findIndex(m => m.options.some(o => o.optionId == 1025 && o.value == "Yes"));
      if (pgfIndex == -1) continue;
      const maxAge = Math.max(...members.filter((_, i) => i != pgfIndex).map(m => m.age));
      const oldestIndex = members.findIndex((m, i) => m.age == maxAge && i != pgfIndex);
      for (const dest of members[pgfIndex].options) {
        if (!selectableOptions.includes(dest.optionId)) continue;
        const src = members[oldestIndex].options.find(o => o.optionId == dest.optionId);
        if (src !== undefined) {
          dest.value = src?.value;
        }
      }
    }
  }
}

export const actions: ActionTree<GetQuoteState, any> = {
  setQuoteLeadData({commit}, data) {
    commit('SET_QUOTE_LEAD_DATA', data)
  },

  getQuoteAddMemberAction({commit}) {
    commit('GET_QUOTE_ADD_MEMBER');
  },

  getQuoteRemoveMemberAction({commit}, index: number) {
    commit('GET_QUOTE_REMOVE_MEMBER', index);
  },

  getQuoteUpdateMemberAction({commit}, data: UpdateMemberData) {
    commit('GET_QUOTE_UPDATE_MEMBER', data);
  },

  getQuoteSetPostcode({commit}, postcode: string) {
    commit('GET_QUOTE_SET_POSTCODE', postcode);
  },

  getQuoteClearPostcode({commit}) {
    commit('GET_QUOTE_CLEAR_POSTCODE');
  },

  getQuoteSetOccupation({commit}, occupation) {
    commit('GET_QUOTE_SET_OCCUPATION', occupation);
  },

  getQuoteSetEmploymentType({commit}, selfEmployed) {
    commit('GET_QUOTE_SET_EMPLOYMENT_TYPE', selfEmployed);
  },

  getQuoteSetMemberOfProfession({commit}, memberOfProfession) {
    commit('GET_QUOTE_SET_MEMBER_OF_PROFESSION', memberOfProfession);
  },

  getQuoteClearOccupation({commit}) {
    commit('GET_QUOTE_CLEAR_OCCUPATION')
  },

  getQuoteClearData({commit}) {
    commit('CLEAR_DATA');
  },

  setMemberDesc({commit}, data) {
    commit('SET_MEMBER_DESCRIPTION', data)
  },

  personalDetailsFilled({commit}, index: number) {
    commit('PERSONAL_DETAILS_FILLED', index)
  },
  async updateQuotePastStartDate({ commit, dispatch, state, getters }) {
    const today = new Date().toISOString().slice(0, 10);
    if (today > state.quotePayload.startDateOfPolicy) {
      commit("CLEAR_QUOTE_COVER_LEVEL"); // ensure quickQuote returns fresh data
      commit("SET_POLICY_START_DATE", today);
      commit('SET_LOADER_FOR_ALL_COVERS', true)
      commit('INCREASE_PENDINGS_REQUEST')
      await dispatch("fetchQuickQuote", getters.selectableOptionIds);
      commit("UPDATE_PGF_OPTIONS", getters.selectableOptionIds);
      commit('DECREASE_PENDINGS_REQUEST')
      commit('SET_LOADER_FOR_ALL_COVERS', false)
    }
  },
  setOptionalBenefitsRaw({ commit }, data) {
    if (data.memberIndex === -1 && data.householdIndex !== -1) {
      commit('SET_OPTIONAL_BENEFITS_PER_HOUSEHOLD', data)
    } else if (data.memberIndex !== -1 && data.householdIndex !== -1) {
      commit('SET_OPTIONAL_BENEFITS_PER_MEMBER_MULTIFAMILY', data)
    } else if (data.memberIndex !== -1 && data.householdIndex === -1) {
      commit('SET_OPTIONAL_BENEFITS_PER_MEMBER_SINGLEFAMILY', data)
    } else if (data.memberIndex === -1 && data.householdIndex === -1) {
      commit('SET_OPTIONAL_BENEFITS_FOR_ALL', data)
    }

    if (data.memberIndex !== -1 && data.setPgf) {
      const pgfData = {
        ...data,
        memberIndex: data.pgfMemberIndex
      };
      if (pgfData.householdIndex !== -1) {
        commit('SET_OPTIONAL_BENEFITS_PER_MEMBER_MULTIFAMILY', pgfData)
      } else if (pgfData.householdIndex === -1) {
        commit('SET_OPTIONAL_BENEFITS_PER_MEMBER_SINGLEFAMILY', pgfData)
      }
    }
  },
  async setOptionalBenefits({ commit, dispatch, state }, data: BenefitsPayload) {
    const condition = state.quote.benefitOptionConditions.find(c =>
      c.optionId == data.optionId &&
      c.value == data.value &&
      (c.cover == data.productCoverLevel));
    if (condition) {
      for (const effect of condition.effects) {
        let currentValueOfEffectOptionId = "";
        if (effect.currentValueCondition != null) {
          currentValueOfEffectOptionId = getMemberOptionValue(data, effect.optionId).toString();
        }

        if (effect.currentValueCondition == null || effect.currentValueCondition == currentValueOfEffectOptionId) {
          for (const value of effect.values) {
            if (value.selected) {
              dispatch('setOptionalBenefitsRaw', { ...data, optionId: effect.optionId, value: value.value });
            }
          }
        }
      }
    }
    dispatch('setOptionalBenefitsRaw', data);

    await dispatch("updateQuotePastStartDate");

    if (!data.productCoverLevel) {
      commit('SET_LOADER_FOR_ALL_COVERS', true)
      for (const index in store.getters.quotePayloadsForAllLevels) {
        commit('INCREASE_PENDINGS_REQUEST')
        try {
          const coverData = store.getters.quotePayloadsForAllLevels[index]
          const { households, ...rest } = await (await api).quickQuoteCustomise(coverData)
          const data2 = { households: households, index: index }
          commit('SET_QUICKQUOTE_CUSTOMISE_BY_INDEX', data2)

          const discounts = {
            discountDetails: rest.discountDetails,
            discountFootnote: rest.discountFootnote,
            termsAndConditions: rest.promotionTermsAndConditions,
            promotionId: rest.promotionId
          };
          commit('SET_QUICKQUOTE_DISCOUNTS_INFO', discounts);
          const replaceData = {
            households: households,
            productId: coverData.productId,
            productCoverLevel: coverData.productCoverLevel
          }
          commit('REPLACE_OPTIONS', replaceData);
        }
        catch {
          router.push({name: 'Error' });
        }
        finally {
          commit('DECREASE_PENDINGS_REQUEST');
        }
      }
      commit('SET_LOADER_FOR_ALL_COVERS', false);
      return
    }

    commit('INCREASE_PENDINGS_REQUEST');
    try {
      const coverData = store.getters.quotePayloadWithoutMemberIds;

      const { households, ...rest } = await (await api).quickQuoteCustomise(coverData)
      commit('SET_QUICKQUOTE_CUSTOMISE', households)

      const discounts = {
        discountDetails: rest.discountDetails,
        discountFootnote: rest.discountFootnote,
        termsAndConditions: rest.promotionTermsAndConditions,
        promotionId: rest.promotionId
      };
      commit('SET_QUICKQUOTE_DISCOUNTS_INFO', discounts);

      const replaceData = {
        households: households,
        productId: data.productId,
        productCoverLevel: data.productCoverLevel
      }
      commit('REPLACE_OPTIONS', replaceData)
    }
    catch {
      router.push({name: 'Error' });
    }
    finally {
      commit('DECREASE_PENDINGS_REQUEST')
    }
  },

  setHouseholdsInQuotePayload({commit}, households: QuoteHousehold[]) {
    commit('SET_HOUSEHOLDS_IN_QUOTE_PAYLOAD', households)
  },

  async fetchQuickQuote({commit}, selectableOptions = []) {
    try {
      const quote = await (await api).quickQuote(this) as QuotePayload
      commit('SET_QUICKQUOTE', { quote, selectableOptions })
      commit('SET_DEFAULT_COVER');
      return quote;
    }
    catch(e) {
      console.error(e);

      router.push({name: 'Error' });
    }
  },

  async fetchQuoteById({commit}, id) {
    try {
      const quote = await (await api).quickquoteById(id)
      const households = await (await api).quoteById(id) as QuotePayload

      commit('SET_QUOTE_BY_ID', households)
      commit('SET_QUICKQUOTE_BY_ID', quote)
      commit('SET_DEFAULT_COVER');
    }
    catch {
      router.push({name: 'Error' });
    }
  },
  async setAsDefaultForHouseholds({commit}, {householdIndex, selectableOptions}) {
    commit('SET_AS_DEFAULT_FOR_HOUSEHOLDS', {householdIndex, selectableOptions})
    commit('SET_DEFAULT_COVER');

    commit('INCREASE_PENDINGS_REQUEST')
    try {
      const { households, ...rest } = await (await api).quickQuoteCustomise(store.getters.quotePayloadWithoutMemberIds)
      commit('SET_QUICKQUOTE_CUSTOMISE', households)
      const discounts = {
        discountDetails: rest.discountDetails,
        discountFootnote: rest.discountFootnote,
        termsAndConditions: rest.promotionTermsAndConditions,
        promotionId: rest.promotionId
      };
      commit('SET_QUICKQUOTE_DISCOUNTS_INFO', discounts);
    }
    catch {
      router.push({name: 'Error' });
    }
    finally {
      commit('DECREASE_PENDINGS_REQUEST')
    }
  },
  async setAsDefaultForHousehold({commit}, {memberIndex, householdIndex, selectableOptions}) {
    commit('SET_AS_DEFAULT_FOR_HOUSEHOLD', {memberIndex, householdIndex, selectableOptions})
    commit('SET_DEFAULT_COVER');

    commit('INCREASE_PENDINGS_REQUEST')
    try {
      const { households, ...rest } = await (await api).quickQuoteCustomise(store.getters.quotePayloadWithoutMemberIds)
      commit('SET_QUICKQUOTE_CUSTOMISE', households)

      const discounts = {
        discountDetails: rest.discountDetails,
        discountFootnote: rest.discountFootnote,
        termsAndConditions: rest.promotionTermsAndConditions,
        promotionId: rest.promotionId
      };
      commit('SET_QUICKQUOTE_DISCOUNTS_INFO', discounts);
    }
    catch {
      router.push({name: 'Error' });
    }
    finally {
      commit('DECREASE_PENDINGS_REQUEST')
    }
  },
  async resetHouseholdToDefault({commit}, {householdIndex}) {
    commit('RESET_HOUSEHOLD_TO_DEFAULT', {householdIndex})

    commit('INCREASE_PENDINGS_REQUEST')
    try {
      const { households, ...rest } = await (await api).quickQuoteCustomise(store.getters.quotePayloadWithoutMemberIds)
      commit('SET_QUICKQUOTE_CUSTOMISE', households)

      const discounts = {
        discountDetails: rest.discountDetails,
        discountFootnote: rest.discountFootnote,
        termsAndConditions: rest.promotionTermsAndConditions,
        promotionId: rest.promotionId
      };
      commit('SET_QUICKQUOTE_DISCOUNTS_INFO', discounts);
    }
    catch {
      router.push({name: 'Error' });
    }
    finally {
      commit('DECREASE_PENDINGS_REQUEST')
    }
  }
}

/**
 * If repricing occurs (on QuoteGeneral)
 * we need to be ready to reassign members in other order,
 * because it is not guaranteed that pricing API will return
 *
 * @param quotePayload
 */
export
function stripMembersIds(quotePayload) {
  return {
    ...quotePayload,
    households: quotePayload.households.map(h => ({
      ...h,
      subscriber: {
        ...h.subscriber,
        id: undefined
      },
      familyMembers: h.familyMembers.map(fm => ({
        ...fm,
        id: undefined
      }))
    }))
  }
}

function getMemberOptionValue(data: BenefitsPayload, otherOptionId) {
  if (data.memberIndex === -1 && data.householdIndex !== -1) {
    return getMemberOptionValue_ForHousehold(data.householdIndex, otherOptionId, data.productId, data.productCoverLevel);
  } else if (data.memberIndex !== -1 && data.householdIndex !== -1) {
    return getMemberOptionValue_ForMemberMultiFamily(data.householdIndex, data.memberIndex, otherOptionId, data.productId, data.productCoverLevel);
  } else if (data.memberIndex !== -1 && data.householdIndex === -1) {
    return getMemberOptionValue_ForMemberSingleFamily(data.memberIndex, otherOptionId, data.productId, data.productCoverLevel);
  } else if (data.memberIndex === -1 && data.householdIndex === -1) {
    // for all
    return getMemberOptionValue_ForHousehold(0, otherOptionId, data.productId, data.productCoverLevel);
  }
  return "";
}

function getMemberOptionValue_ForHousehold(householdIndex, optionId, productId, productCoverLevel) {
  const subscriberOption = state.quotePayload.households[householdIndex].subscriber.options
    .find(option => option.optionId === optionId && state.quotePayload.productId === productId && productCoverLevel === state.quotePayload.productCoverLevel);
  if (subscriberOption) {
    return subscriberOption.value;
  }
  return "";
}

function getMemberOptionValue_ForMemberMultiFamily(householdIndex, memberIndex, optionId, productId, productCoverLevel) {
  const coverOption = state.quote.householdOptions[householdIndex].memberOptions[memberIndex].coverOptions
    .find(option => option.optionId === optionId && option.productId === productId && option.coverLevel === productCoverLevel)
  if (coverOption) {
    return coverOption.value;
  }
  return "";
}

function getMemberOptionValue_ForMemberSingleFamily(memberIndex, optionId, productId, productCoverLevel) {
  const coverOption = state.quote.householdOptions[0].memberOptions[memberIndex].coverOptions
    .find(option => option.optionId === optionId && option.productId === productId && (productCoverLevel ? option.coverLevel === productCoverLevel : true))
  if (coverOption) {
    return coverOption.value;
  }
  return "";
}

export const GetQuote = {
  state,
  getters,
  actions,
  mutations
};
