import { default as _api } from "@/api";

export const checkAuthAndRedirect = async (returnUrl, store) => {
  const accessToken = localStorage.getItem("ACCESS_TOKEN");
  const refreshToken = localStorage.getItem("REFRESH_TOKEN");

  if (refreshToken) {
    if (!accessToken) {
      // refresh access token and and push to return url
      await _api
        .accessToken(refreshToken)
        .then(async (res) => {
          store.commit("setAccessToken", res);
          localStorage.setItem("ACCESS_TOKEN", res);
        })
        .catch((err) => {
          redirectToLogin(returnUrl, store);
        });
    }
    return returnUrl;
  } else {
    // redirect to login
    await redirectToLogin(returnUrl, store);
    return null;
  }
};

export const buildReturnUrl = async (config, returnUrl, store) => {
  const redirectUrl = config.myWpaLoginEndpoint;
  const loginUrl = config.myWpaUrl;
  const channel = config.authChannel;
  const env = config.authEnv;
  let encodedReturnUrl;
  let url;

  if (returnUrl) {
    encodedReturnUrl = encodeURIComponent(`/callback?return_url=${returnUrl}`);
  } else {
    encodedReturnUrl = encodeURIComponent(`/callback`);
  }

  if (env) {
    url = `${loginUrl}${redirectUrl}?return_url=${encodedReturnUrl}&channel=${channel}&env=${env}`;
  } else {
    url = `${loginUrl}${redirectUrl}?return_url=${encodedReturnUrl}&channel=${channel}`;
  }

  const accessToken = localStorage.getItem("ACCESS_TOKEN");
  const refreshToken = localStorage.getItem("REFRESH_TOKEN");

  if (refreshToken) {
    if (!accessToken) {
      await _api
        .accessToken(refreshToken)
        .then(async (res) => {
          store.commit("setAccessToken", res);
          localStorage.setItem("ACCESS_TOKEN", res);
        })
        .catch((err) => {
          return url;
        });
    }

    return "/my-profile";
  }

  return url;
};

const redirectToLogin = async (returnUrl, store) => {
  let configuration = store.getters.config;

  while (!configuration) {
    await sleep(200);
    configuration = store.getters.config;
  }

  localStorage.setItem("TMP_STORE", JSON.stringify(store.state));

  let url = await buildReturnUrl(configuration, returnUrl, store);
  document.location.href = url;
};

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
