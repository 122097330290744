import api from '@/api'

export const state = {
  categoriesAnswers: [],
  furtherQuestionsList: [],
  videoModal: false,
  underwritingSidebar: false,
  underwritings: [],
  available: [2, 1, 3, 4],
}

export const mutations = {
  getFurtherQuestionsList (state, list) {
    state.furtherQuestionsList = list
  },
  toggleJoiningMethodInfoSidebar (state) {
    state.underwritingSidebar = !state.underwritingSidebar
  },
  toggleVideoModal () {
    state.videoModal = !state.videoModal
  },
  clearApplication () {
    state.videoModal = false
  },

  // storing all answers
  answerPrimaryQuestion (state, { primaryQuestionId, primaryQuestionAnswerId: answerId }) {
    const primaryQuestion = state.categoriesAnswers.filter(category => {
      return category.primaryQuestionId === primaryQuestionId
    })
    if(!primaryQuestion || !primaryQuestion[0]) {
      console.warn('non-existing primary question')
      return
    }
    primaryQuestion[0].answerId = answerId
  },
  setCategoriesListWithAnswers (state, list) {
    state.categoriesAnswers = list
  },
  setAvailableUwMethods(state, available) {
    state.available = available;
  }
}

export const actions = {
  async fetchMedicalDeclarationCategoryPost({commit}, payload) {
    return await (await api).medicalDeclarationCategoryPost(this, payload);
  }
}

export const getters = {
  categoriesAnswers: state => state.categoriesAnswers,
  underwritingSidebar: state => state.underwritingSidebar,
  videoModal: state => state.videoModal,
  hdMembers: (state, getters, rootState) => {
    if (!getters.application || !getters.application.households[0]) {
      return []
    }
    return getters.application.households[0].applicationMembers
  },
  underwritings: (state, getters, rootState) => rootState.config && rootState.config.underwritings,
  available: (state, getters, rootState) => state.available,
  membersByMethod(state, getters, rootState) {
    const membersByMethod = {}
    if (!getters.underwritings) {
      return membersByMethod
    }
    getters.underwritings.map(u => u.value).map(e => {
      const members = getters.hdMembers.filter(m => m.joiningMethod == e)
      if (members.length) {
        membersByMethod[e] = members
      }
    })
    return membersByMethod
  },
  getUWStepbarData(state, getters, rootState) {
    return getters.underwritings.map(e => {
      return {
        label: e.label,
        done: false,
        uwStep: e.value,
        id: e.value,
        routes: ['ApplyHealthCategories'],
        counter: (getters.membersByMethod[e.value] || {length: 0}).length,
      }
    }).filter(e => e.counter > 0)
  },
  moratoriumId: (state, _, rootState) => rootState.config.possibleUnderwritingTypeIds.moratorium,
  fmuValue: (state, _, rootState) => rootState.config.possibleUnderwritingTypeIds.fmu,
}

export const MedicalDeclaration = {
  state,
  getters,
  actions,
  mutations
}
