<template>
  <div v-show="showLoader" class="main-loader">
    <div class="dot-loader"></div>
    <div class="dot-loader"></div>
    <div class="dot-loader"></div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean
    }
  },
  computed: {
    showLoader() {
      return this.loading || this.$store.state.showLoader;
    }
  }
};
</script>

<style lang="scss" scoped>
.main-loader {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
}

.dot-loader {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #0057B7;
  position: relative;
  -webkit-animation: 1.2s scaleDown ease-in-out infinite;
  animation: 1.2s scaleDown ease-in-out infinite;
}

.dot-loader:nth-child(2) {
  margin: 0 15px;
  -webkit-animation: 1.2s scaleDown ease-in-out infinite 0.15555s;
  animation: 1.2s scaleDown ease-in-out infinite 0.15555s;
}

.dot-loader:nth-child(3) {
  -webkit-animation: 1.2s scaleDown ease-in-out infinite 0.3s;
  animation: 1.2s scaleDown ease-in-out infinite 0.3s;
}

@-webkit-keyframes scaleDown {
  0%,80%,100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scaleDown {
  0%,80%,100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
