<template>
  <div id="app" :class="{ theme: hifitheme }">
    <t-navbar />
    <div class="Container">
      <template v-if="loadingApplication">
        <dot-loader-fullscreen />
      </template>
      <template v-else>
        <router-view :secondary-questions="secondaryQuestions" :only-navbar.sync="onlyNavbar" />
      </template>
    </div>
    <div class="logout-warning">
      <b-modal id="modal-logout-warning" v-model="displayLogoutWarning" @ok="handleOk" ok-only no-close-on-backdrop hide-header>
        <p>You have been inactive for 10 minutes and will be logged off in {{ timeLeft }} seconds. Please press OK if you wish to continue.</p>
      </b-modal>
    </div>
    <div class="fpn-approval">
      <b-modal id="modal-fpn" size="lg" scrollable v-model="displayFPN" no-close-on-backdrop hide-header hide-footer>
        <div v-html="$store.state.fpnContent"></div>
        <div>
          <button :class="[brandingClassName, 'fpn-approve-btn']" @click="handleAcceptFPN">I ACCEPT</button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { mapGetters } from "vuex";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component("s-input", SmartInput);
Vue.component("s-button", SmartInput);
import Menu from "./components/Menu.vue";
import Navbar from "./components/Navbar.vue";
import SmartInput from "@/components/Apply/SmartInput.vue";
import SmartButton from "@/components/Apply/SmartButton.vue";
import LoaderDots from "@/components/LoaderDots.vue";
import DotLoader from "@/components/DotLoader.vue";
import CookieCivicConfig from "./common/cookie-civic.js";
import cookie from "js-cookie";
import { forceLogout, logoutAndClear, logoutWarning } from "./api/fetch-middleware";
import apiCall from "./api/fetch-middleware";
import api from "./api";
Vue.prototype.$cookie = cookie;
Vue.component("s-input", SmartInput);
Vue.component("s-button", SmartButton);
Vue.component("loader-dots", LoaderDots);
Vue.component("dot-loader-fullscreen", DotLoader);
export default Vue.extend({
  components: {
    "t-menu": Menu,
    "t-navbar": Navbar,
  },
  provide: {
    api() {
      return api;
    },
  },
  data: () => ({
    hifitheme: true,
    secondaryQuestions: [],
    onlyNavbar: false,
    timeLeft: 59, // seconds,
    extendedSession: false,
  }),
  created() {
    interface Window {
      dataLayer: any;
    }
    const currentWindow = window as any;
    if (currentWindow !== currentWindow.parent) {
      currentWindow.parent.location.href = currentWindow.location.href;
    }
  },
  watch: {
    hifitheme: {
      immediate: true,
      handler(v) {
        const classes = (this as any).$parent.$el.classList;
        classes[v ? "add" : "remove"].call(classes, "theme");
      },
    },
    displayLogoutWarning(newValue, oldValue) {
      if (newValue) {
        this.countDownTimer();
      } else {
        this.timeLeft = 59;
      }
    },
  },
  computed: {
    ...mapGetters(["loadingApplication", "brandingClassName"]),
    displayLogoutWarning: {
      get() {
        return this.$store.state.showLogoutWarning;
      },
      set(newValue) {
        return newValue;
      },
    },
    displayFPN: {
      get() {
        return this.$store.state.showFPNDialog;
      },
      set(newValue) {
        return newValue;
      },
    },
  },
  methods: {
    isExistScript(url) {
      var scripts = document.getElementsByTagName("script");
      for (var i = scripts.length; i--; ) {
        if (scripts[i].src == url) return true;
      }
      return false;
    },
    async handleOk() {
      const apiObj = await api;
      const apiUrl = apiObj.apiSpec.API;
      await apiCall.post(`${apiUrl}api/authorization/getAccessToken`);
      this.$store.commit("SET_SHOW_LOGOUT_WARNING", false);
      this.extendedSession = true;
    },
    async handleAcceptFPN() {
      this.$store.commit("SET_IS_FPN_CONFIRMED", true);
      this.$store.commit("SET_SHOW_FPN_DIALOG", false);
    },
    countDownTimer() {
      if (this.timeLeft > 0 && !this.extendedSession) {
        setTimeout(() => {
          this.timeLeft -= 1;
          this.countDownTimer();
        }, 1000);
      } else if (this.timeLeft === 0 && !this.extendedSession) {
        // logout
        this.$store.commit("SET_SHOW_LOGOUT_WARNING", false);
        logoutAndClear();
      } else {
        // session extended set to default
        this.extendedSession = false;
      }
    }
  },
  mounted() {
    var shouldAdd = !this.isExistScript("https://cc.cdn.civiccomputing.com/9/cookieControl-9.x.min.js");
    if (shouldAdd) {
      var script = document.createElement("script");
      script.crossOrigin = "anonymous";
      script.src = "https://cc.cdn.civiccomputing.com/9/cookieControl-9.x.min.js";
      document.body.appendChild(script);
      script.onload = function() {
        (window as any).CookieControl.load(CookieCivicConfig);
      };
    }
  },
});
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&family=Signika:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM Plex Sans");

.theme {
  @import "@/theme/main";
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: Inter;
  color: #3c3c3b;
}
#ccc #ccc-notify {
  font-family: Inter !important;
  .ccc-notify-buttons,
  button {
    font-family: Inter !important;
  }
}
#ccc #ccc-content,
#ccc #ccc-notify,
#ccc-button-holder .ccc-notify-button,
#ccc-dismiss-button.ccc-notify-button span {
  font-family: Inter !important;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 300;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong,
  b {
    font-weight: 500 !important;
  }
  small {
    font-weight: 300;
  }
  input {
    color: #3c3c3b;
    &::placeholder {
      color: #3c3c3b;
    }
  }
  label {
    font-weight: 400 !important;
  }
}

.Container {
  width: 100%;
  margin-top: 64px;
}

.rb {
  position: fixed;
  right: 0px;
  bottom: 0px;
}
#modal-fpn {
  font-weight: 300;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong,
  b {
    font-weight: 500 !important;
  }
}
.fpn-approve-btn {
  width: 100%;
  min-width: 118px;
  min-height: 44px;
  border: 0 solid #0057b7;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  font: 14px "Inter";
  font-weight: 500;
  text-align: center;
  letter-spacing: 0px;
  &:focus {
    outline-color: #0057b7;
    outline: #000 auto 1px;
  }
  background: #0057b7 0% 0% no-repeat padding-box;
  border-color: #0057b7;
  color: #fff;
}
</style>
