import { GetQuote, state } from "./GetQuote/index";
import {
  paramTypes,
  isAdultRelationship,
  isChildRelationship,
  coverType,
  coverTypes,
  getApplicationId,
  relationship,
} from "@/utils/onlineInvite";
import { Auth } from "./Auth/auth";
import { MedicalDeclaration } from "./MedicalDeclaration/index";
import { MultifamilyQuote } from "./MultifamilyQuote/index";
import { QuoteSummary } from "./QuoteSumup/quote";
import { Renewals } from "./Renewals/index";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    benefits: {
      coverDescriptions: [],
    },
    memberId: -1,
    productId: undefined,
    productCoverLevel: undefined,
    householdId: undefined,
    households: [],
    flexOrMultiFamily: 0,
    config: null,
    pageDecisions: {
      coverForWhom: "",
      coverType: null,
      extendedFamilyLevel: null,
      privateTreatmentLevel: null,
      treatmentOption: null,
      selectedOptions: [],
    },
    underwrittingTypeId: 1, //moratorium value
    recommendedProduct: {
      productId: null,
      productName: null,
      productDescription: null,
      productIcon: null,
      coverLevel: null,
      coverLevelDescription: null,
      header: null,
      body: null,
      footer: null,
      isMultiFamily: false,
      isDental: false,
      isSwitch: false,
      isTopUp: false,
      benefitsList: [],
      optionalExtrasList: [],
    },
    isMultiFamily: false,
    isDental: false,
    isSwitch: false,
    isTopUp: false,
    membershipRequestByAppId: {},
    membershipRequest: null,
    membershipRequestParameters: {},
    ageData: {
      minAdultAge: 18,
      maxPersonAge: 65,
      minPersonAge: 1,
    },
    appPagesCompleted: [] as any,
    application: null as any,
    sameCertificate: undefined,
    hasCertificate: undefined,
    appid: undefined,
    healthQuestionsAnswers: [],
    reviewData: {},
    conditions: [],
    showLoader: false,
    flowLastSaved: undefined,
    showLogoutWarning: false,
    showFPNDialog: false,
    fpnContent: null,
    isFPNConfirmed: false,
    loadingApplication: false,
    branding: {
      brandingClassName: null,
      logoSource: null,
    }
  },
  getters: {
    ageData: (state) => state.ageData,
    config: (state) => state.config,
    coverType: (state) => state.pageDecisions.coverType,
    recommendedProductName: (state) => state.recommendedProduct.productName,
    isMultiFamily: (state) => state.isMultiFamily,
    isDental: (state) => state.isDental,
    isSwitch: (state) => state.isSwitch,
    isTopUp: (state) => state.isTopUp,
    productId: (state) => state.recommendedProduct.productId,
    application: (state) => state.application,
    healthQuestionsAnswers: (state) => state.healthQuestionsAnswers,
    sameCertificate: (state) => state.sameCertificate,
    hasCertificate: (state) => state.hasCertificate,
    showLoader: (state) => state.showLoader,
    showLogoutWarning: (state) => state.showLogoutWarning,
    showFPNDialog: (state) => state.showFPNDialog,
    fpnContent: (state) => state.fpnContent,
    isFPNConfirmed: (state) => state.isFPNConfirmed,
    quoteValidFor: (state) =>
      (state.config || { quoteValidFor: 14 }).quoteValidFor,
    memberId: (state) => state.memberId,
    householdId: (state) => state.householdId,
    loadingApplication: (state) => state.loadingApplication,
    membershipRequest: (state) => state.membershipRequest,
    currentProductOptions(state: any) {
      const defaultOptions = {
        fields: {},
      };
      let options = null as any;
      if (state.application) {
        options = state.config.productOptions[state.application.productId];
      } else if (state.membershipRequest) {
        options =
          state.config.productOptions[state.membershipRequest.productId];
      }

      if (options == null) return defaultOptions;
      return { ...defaultOptions, ...options };
    },
    isInviteUser(state) {
      return !!(
        Object.keys(state.membershipRequestByAppId).length ||
        state.membershipRequest
      );
    },
    isOnlineInvite(state, getters) {
      if (!state.application) return false;
      if (state.membershipRequest) return true;
      return false;
    },
    isOnlineInviteMultiFamily(state, getters) {
      if (!state.application) return false;
      const productId = state.application.productId;
      return productId === 957 && getters.isOnlineInvite;
    },
    isOnlineInviteFlexi(state, getters) {
      if (!state.application) return false;
      const productId = state.application.productId;
      return [844, 847, 848].includes(productId) && getters.isOnlineInvite;
    },
    isFamilyOffice(state) {
      const productId = state.application?.productId;
      return productId === 951;
    },
    membershipRequestCover(state) {
      return state.membershipRequestParameters[paramTypes.COVER];
    },
    isMembershipRequestDependantsOnly(state, getters) {
      return [3, 4].includes(getters.membershipRequest?.type);
    },
    isMembershipRequestUnderwritingOnly(state, getters) {
      return [2, 4].includes(getters.membershipRequest?.type);
    },
    canAddChildDependants(state, getters) {
      if (getters.isMembershipRequestUnderwritingOnly) return false;
      const cover = getters.membershipRequestCover;
      const isMcd = getters.currentProductOptions.isMcd;
      const isEbd = getters.currentProductOptions.isEbd;
      if (!isMcd && !isEbd) return false;
      const maxNumberOfDependants =
        state.membershipRequestParameters[paramTypes.NUMBER_OF_DEPENDANTS];
      if (maxNumberOfDependants && +maxNumberOfDependants > 0) {
        const numDependants =
          state.application.households[0].applicationMembers.filter(
            (am) =>
              ![null, relationship.SUBSCRIBER].includes(am.memberRelationship)
          ).length;
        if (maxNumberOfDependants <= numDependants) return false;
      } else {
        const numChildren =
          state.application.households[0].applicationMembers.filter((am) =>
            isChildRelationship(am.memberRelationship)
          ).length;
        if (isMcd && numChildren >= 9) return false;
        if (isEbd && numChildren >= 5) return false;
      }
      return coverTypes.WITH_CHILD.includes(cover) || cover == null;
    },
    canAddAdultDependants(state, getters) {
      if (getters.isMembershipRequestUnderwritingOnly) return false;
      const cover = getters.membershipRequestCover;
      const isMcd = getters.currentProductOptions.isMcd;
      const isEbd = getters.currentProductOptions.isEbd;
      if (!isMcd && !isEbd) return false;
      if (
        state.application.households[0].applicationMembers.some((am) =>
          isAdultRelationship(am.memberRelationship)
        )
      )
        return false;
      const maxNumberOfDependants =
        state.membershipRequestParameters[paramTypes.NUMBER_OF_DEPENDANTS];
      if (maxNumberOfDependants && +maxNumberOfDependants > 0) {
        const numDependants =
          state.application.households[0].applicationMembers.filter(
            (am) =>
              ![null, relationship.SUBSCRIBER].includes(am.memberRelationship)
          ).length;
        if (maxNumberOfDependants <= numDependants) return false;
      }
      return coverTypes.WITH_ADULT.includes(cover) || cover == null;
    },
    canChangeDependantAmount(state, getters) {
      if (getters.isMembershipRequestUnderwritingOnly) return false;
      const cover = getters.membershipRequestCover;
      const isMcd = getters.currentProductOptions.isMcd;
      const isEbd = getters.currentProductOptions.isEbd;
      const maxNumberOfDependants =
        state.membershipRequestParameters[paramTypes.NUMBER_OF_DEPENDANTS];
      if (!isMcd && !isEbd) return false;
      return cover != coverType.SINGLE;
    },
    canChooseUnderwritingTerm(state, getters) {
      return (
        !getters.currentProductOptions.isMcd &&
        !getters.currentProductOptions.isEbd
      );
    },
    brandingClassName: (state) => state.branding.brandingClassName,
    showInviteCertificateUpload(state, getters) {
      if (getters.canChooseUnderwriting) return false;
      if (
        !state.application?.households
          ?.flatMap((h) => h.applicationMembers)
          ?.some((am) => am.joiningMethod == 4 || am.joiningMethod == 3)
      )
        return false;
      if (
        getters.isOnlineInviteFlexi ||
        getters.isOnlineInviteMultiFamily ||
        getters.isFamilyOffice
      )
        return false;
      return getters.isOnlineInvite;
    },
    groupSpecificationIds(state, getters) {
      const groups = [1];
      const members = getters.application.households.flatMap(
        (x) => x.applicationMembers
      );

      if (members.some((x) => x.sexAtBirth == 1)) {
        groups.push(2);
      }
      if (members.some((x) => x.sexAtBirth == 2)) {
        groups.push(3);
      }

      return groups;
    },
  },
  mutations: {
    CLEAR_DATA(state) {
      state.memberId = -1;
      state.productId = undefined;
      state.productCoverLevel = undefined;
      state.householdId = undefined;
      state.households = [];
    },
    setConfig(state, config) {
      state.config = config;
    },
    setCoverPhrase(state, path) {
      state.pageDecisions.coverForWhom = path;
    },
    setCoverType(state, type) {
      state.pageDecisions.coverType = type;
    },
    setExtendedFamilyCoverLevel(state, level) {
      state.pageDecisions.extendedFamilyLevel = level;
    },
    setPrivateTreatmentLevel(state, level) {
      state.pageDecisions.privateTreatmentLevel = level;
    },
    setTreatmentOption(state, treatment) {
      state.pageDecisions.treatmentOption = treatment;
    },
    setSelectedOptions(state, options) {
      state.pageDecisions.selectedOptions = options;
    },
    setRecommendedProduct(state, data) {
      state.recommendedProduct = data;
      state.productId = data.productId;
      state.productCoverLevel = data.coverLevel;
      state.isMultiFamily = data.isMultiFamily;
      state.isDental = data.isDental;
      state.isSwitch = data.isSwitch;
      state.isTopUp = data.isTopUp;
      // GetQuote.state.quotePayload.productId = data.productId;
      // GetQuote.state.quotePayload.productCoverLevel = data.coverLevel;
    },
    setMemberId(state, value) {
      state.memberId = value;
    },
    setProductId(state, value) {
      state.productId = value;
      // GetQuote.state.quotePayload.productId = value
    },
    setProductCoverLevel(state, value) {
      state.productCoverLevel = value;
      // GetQuote.state.quotePayload.productCoverLevel = value
    },
    set(state, obj) {
      if (obj.length) {
        const [key, value] = obj;
        state[key] = value;
      } else {
        const { key, value } = obj;
        state[key] = value;
      }
    },
    setStepAsCompleted(state, data) {
      const appPageName = data.name;
      const isCompleted = data.completed;
      if (isCompleted) {
        if (!state.appPagesCompleted.includes(appPageName)) {
          state.appPagesCompleted.push(appPageName);
        }
      } else {
        if (state.appPagesCompleted.includes(appPageName)) {
          state.appPagesCompleted = state.appPagesCompleted.filter(
            (p) => p !== appPageName
          );
        }
      }
    },
    setApplication(state, data) {
      const isOnlineInviteMultiFamily = data
        ? data.productId === 957 && data.households.length == 1
        : false;
      var isOnlineInviteCompleteHealth = false;
      if(state.membershipRequest && data && data.productId === 1072){
        isOnlineInviteCompleteHealth = true;
      }

      state.application = data;
      state.productId = data ? data.productId : state.productId;
      state.isMultiFamily = data
        ? data.productId === 957 && data.households.length > 1
        : false;
      state.isDental = data ? data.productId === 682 : false;
      state.isSwitch = data
        ? [844, 847, 848, 951].includes(data.productId) ||
          isOnlineInviteMultiFamily || 
          isOnlineInviteCompleteHealth
        : false;
      state.isTopUp = data
        ? data.productId === 859 || data.productId === 682
        : false;
    },
    setApplicationIntermediary(state, data) {
      state.application.intermediaryId = data;
    },
    setApplicationStartDate(state, data) {
      if (state.application !== null) {
        state.application.startDate = data;
      }
    },
    setApplicationDirectDebit(state, data) {
      if (state.application !== null) {
        Vue.set(state.application, "directDebitPaymentAccepted", data);
      }
    },
    setApplicationPaymentInterval(state, data) {
      if (state.application !== null) {
        state.application.paymentInterval = data;
      }
    },
    setApplicationPaymentMethod(state, data) {
      if (state.application !== null) {
        state.application.paymentMethod = data;
      }
    },
    addApplicationMember(state, member) {
      const index =
        state.application.households[0].applicationMembers.findIndex(
          (m) => m.id == member.id
        );
      if (index != -1) {
        Vue.set(
          state.application.households[0].applicationMembers,
          index,
          member
        );
      } else {
        state.application.households[0].applicationMembers.push(member);
      }
    },
    removeApplicationMember(state, memberId) {
      const index =
        state.application.households[0].applicationMembers.findIndex(
          (m) => m.id == memberId
        );
      state.application.households[0].applicationMembers.splice(index, 1);
    },
    setApplicationMember(state, data) {
      if (state.application) {
        if (data.householdId) {
          const householdIndex = state.application.households.findIndex(
            (h) => h.id === data.householdId
          );
          const memberIndex = state.application.households[
            householdIndex
          ].applicationMembers.findIndex((m) => m.id === data.memberId);

          Vue.set(
            state.application.households[householdIndex].applicationMembers,
            memberIndex,
            {
              ...state.application.households[householdIndex]
                .applicationMembers[memberIndex],
              ...data.payload,
            }
          );
        } else {
          const memberIndex =
            state.application.households[0].applicationMembers.findIndex(
              (m) => m.id === data.memberId
            );

          Vue.set(
            state.application.households[0].applicationMembers,
            memberIndex,
            {
              ...state.application.households[0].applicationMembers[
                memberIndex
              ],
              ...data.payload,
            }
          );
        }
      }
    },
    setSameCertificate(state, data) {
      state.sameCertificate = data;
    },
    setHasCertificate(state, data) {
      state.hasCertificate = data;
    },
    setMembershipRequests(state, membershipRequests) {
      state.membershipRequestByAppId = {};
      for (const mr of membershipRequests) {
        const appId = getApplicationId(mr);
        if (appId) {
          Vue.set(state.membershipRequestByAppId, appId, mr);
        }
      }
    },
    setMembershipRequest(state, data) {
      state.membershipRequest = data;
    },
    setMembershipRequestParameters(state, data) {
      state.membershipRequestParameters = Object.fromEntries(
        data.map((p) => [p.parameterTypeId, p.value])
      );
    },
    setApplicationHousehold(state, data) {
      if (state.application) {
        if (data.householdId) {
          const householdIndex = state.application.households.findIndex(
            (h) => h.id === data.householdId
          );
          const memberIndex = state.application.households[
            householdIndex
          ].applicationMembers.findIndex((m) => m.id === data.memberId);

          state.application.households = [
            ...state.application.households.slice(0, householdIndex),
            {
              ...state.application.households[householdIndex],
              ...data.payload,
            },
            ...state.application.households.slice(householdIndex + 1),
          ];
        } else {
          Vue.set(state.application.households, 0, {
            ...state.application.households[0],
            ...data.payload,
          });
        }
      }
    },
    setPolicyHolder(state, data) {
      if (state.application && state.application.policyHolder) {
        state.application.policyHolder = {
          ...state.application.policyHolder,
          ...data,
        };
      }
    },
    setReviewData(state, data) {
      state.reviewData = data;
    },
    setMembersConditions(state, data) {
      state.conditions = data;
    },
    setLoadingApplication(state, loadingApplication) {
      state.loadingApplication = loadingApplication;
    },

    SET_MORATORIUM_PERMISSION(state, payload: { memberId; boolean }) {
      const chosenMember =
        state.application.households[0].applicationMembers.find(
          (member) => member.id === payload.memberId
        );

      if (chosenMember) {
        chosenMember.allowMoratorium = payload.boolean;
      }
    },
    SET_HEALTH_QUESTIONS_ANSWERS(state, data: any) {
      state.healthQuestionsAnswers = data;
    },
    clearApplication(state) {
      state.application = null;
      state.appPagesCompleted = [];
    },
    SET_LOADER(state, bool) {
      state.showLoader = bool;
    },
    SET_SHOW_LOGOUT_WARNING(state, showLogoutWarning) {
      state.showLogoutWarning = showLogoutWarning;
    },
    SET_SHOW_FPN_DIALOG(state, showFPNDialog) {
      state.showFPNDialog = showFPNDialog;
    },
    SET_FPN_CONTENT(state, fpnContent) {
      state.fpnContent = fpnContent;
    },
    SET_IS_FPN_CONFIRMED(state, isFPNConfirmed) {
      state.isFPNConfirmed = isFPNConfirmed;
    },
    SET_APPLICATION_POLICYNAME(state, policyName) {
      state.application.policyName = policyName;
    },
    initialiseStore(state) {
      // Check if the ID exists
      if (localStorage.getItem("TMP_STORE")) {
        // Replace the state object with the stored item
        const tmpStore = JSON.parse(localStorage.getItem("TMP_STORE") || "");
        if (tmpStore) {
          const {
            MultifamilyQuote: multifamilyQuote,
            GetQuote: getQuote,
            Auth: auth,
            QuoteSummary: quoteSummary,
            MedicalDeclaration: medicalDeclaration,
            ...currentStore
          } = tmpStore;
          Object.assign(state, currentStore);

          if (multifamilyQuote)
            Object.assign(MultifamilyQuote.state, multifamilyQuote);
          if (getQuote) {
            const { occupationData, quote, quotePayload, ...restProps } =
              getQuote;
            Object.assign(GetQuote.state, restProps);
            Object.assign(GetQuote.state.occupationData, occupationData);
            Object.assign(GetQuote.state.quote, quote);
            Object.assign(GetQuote.state.quotePayload, quotePayload);
          }
          if (auth) Object.assign(Auth.state, auth);
          if (quoteSummary) Object.assign(QuoteSummary.state, quoteSummary);
          if (medicalDeclaration)
            Object.assign(MedicalDeclaration.state, medicalDeclaration);

          localStorage.removeItem("TMP_STORE");
        }
      }
    },
    SET_BRANDING(state, { brandingClassName, logoSource }) {
      state.branding.brandingClassName = brandingClassName;
      state.branding.logoSource = logoSource;
    },
    setApplicationRequireRepricing(state, data) {
      state.application.requireRepricing = data;
    },
  },
  actions: {
    clearQuoteData({ dispatch }) {
      dispatch("getQuoteClearData");
      dispatch("multifamilyClearData");
    },
    setStepAsCompletedAction({ commit }, data) {
      commit("setStepAsCompleted", data);
    },
    setApplicationAction({ commit }, data) {
      commit("setApplication", data);
    },
    setApplicationDirectDebit({ commit }, data) {
      commit("setApplicationDirectDebit", data);
    },
    setApplicationPaymentInterval({ commit }, data) {
      commit("setApplicationPaymentInterval", data);
    },
    setApplicationPaymentMethod({ commit }, data) {
      commit("setApplicationPaymentMethod", data);
    },
    setSameCertificateAction({ commit }, data) {
      commit("setSameCertificate", data);
    },
    setHasCertificateAction({ commit }, data) {
      commit("setHasCertificate", data);
    },
    clearApplicationAction({ commit }, data) {
      commit("clearApplication", data);
    },
    setApplicationMember({ commit }, data) {
      commit("setApplicationMember", data);
    },
    setApplicationHousehold({ commit }, data) {
      commit("setApplicationHousehold", data);
    },
    setMoratoriumPermissions({ commit }, data) {
      commit("SET_MORATORIUM_PERMISSION", data);
    },
    setHealthQuestionsAnswers({ commit }, data) {
      commit("SET_HEALTH_QUESTIONS_ANSWERS", data);
    },
    setPolicyHolder({ commit }, data) {
      commit("setPolicyHolder", data);
    },
    setProductCoverLevelAction({ commit }, value) {
      commit("setProductCoverLevel", value);
      commit("SET_INITIAL_OPTIONS_PER_MEMBER");
    },
    setReviewData({ commit }, value) {
      commit("setReviewData", value);
    },
    setMembersConditions({ commit }, value) {
      commit("setMembersConditions", value);
    },
    setLoadingApplication({ commit }, value) {
      commit("setLoadingApplication", value);
    },
    setLoader({ commit }, bool) {
      commit("SET_LOADER", bool);
    },
    setShowLogoutWarning({ commit }, bool) {
      commit("SET_SHOW_LOGOUT_WARNING", bool);
    },
    setShowFPNDialog({ commit }, bool) {
      commit("SET_SHOW_FPN_DIALOG", bool);
    },
    setFPNContent({ commit }, data) {
      commit("SET_FPN_CONTENT", data);
    },
    setIsFPNConfirmed({ commit }, bool) {
      commit("SET_IS_FPN_CONFIRMED", bool);
    },
    setApplicationRequireRepricing({ commit }, data) {
      commit("setApplicationRequireRepricing", data);
    },
  },
  modules: {
    MultifamilyQuote,
    GetQuote,
    Auth,
    QuoteSummary,
    MedicalDeclaration,
    Renewals,
  },
});
