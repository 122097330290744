
export const state = {
  applicationSummaries: [],
  quoteSummaries: [],
  }
  
  export const getters = {
    applicationSummaries: state => state.applicationSummaries,
    quoteSummaries: state => state.quoteSummaries
  }
  
  export const mutations = {
    setApplicationSummeries (state, data) {
      state.applicationSummaries = data
    },
    setQuoteSummeries (state, data) {
      state.quoteSummaries = data
    }
  }
  
  export const actions = {
    setApplicationSummeriesAction({commit}, data) {
      commit('setApplicationSummeries', data)
    },
    setQuoteSummeriesAction({commit}, data) {
      commit('setQuoteSummeries', data)
    }
  }
  
  export const QuoteSummary = {
    state,
    getters,
    actions,
    mutations
  }
  