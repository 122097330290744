import AddFamily from "@/assets/add-family-member-icon.svg"
import add from "@/assets/add.svg"
import addHousehold from '@/assets/add-household.svg';
import addWhite from "@/assets/e-add.svg";
import appStore from "@/assets/appstore.png";
import application from "@/assets/webapp.png";
import arrow from "@/assets/arrow.svg";
import arrow2 from "@/assets/arrow2.svg";
import arrowRight from "@/assets/arrow-right.svg";
import blackUser from "@/assets/black-user.svg";
import blueLeftArrow from '@/assets/small-blue-left-arrow.svg'
import bluePhone from "@/assets/blue-phone.svg";
import bluePin from "@/assets/blue-pin.svg";
import bluePlus from '@/assets/blue-plus.svg';
import blueRemove from "../assets/blue-remove.svg";
import blueX from "../assets/blue-x.svg";
import calendar from "@/assets/calendar-date-2.svg";
import cardAm from "@/assets/americanexpress-logo.svg";
import cardMastercard from "@/assets/Mastercard-logo.svg";
import cardVisa from "@/assets/Visa-logo.svg";
import check from "@/assets/Green tick white outline 1px.svg";
import checkGray from "@/assets/c-check.svg";
import checkGray2 from '@/assets/check-gray.svg'
import checkGreen from "@/assets/check.svg";
import checkedDocumentation from '@/assets/checked-documentation-icon.svg';
import child from "@/assets/child.svg"
import circleCheckbox from '@/assets/circle-checkbox.svg';
import circleCheckboxUnchecked from '@/assets/circle-checkbox-unchecked.svg'
import circleDownGreen from '@/assets/circle-down-green.svg';
import circleUpRed from '@/assets/circle-up-red.svg';
import clock from "@/assets/clock-time.svg"
import close from "@/assets/close.svg";
import codeQr from "@/assets/qr.png";
import community from "@/assets/community.svg";
import couple from "@/assets/couple.svg";
import dataDownload from '@/assets/data-download-icon.svg';
import dotFull from '@/assets/dot.svg';
import family from "@/assets/family.svg";
import familyMultiple from '@/assets/family-multiple-people.svg';
import exclamationMark from '@/assets/exclamation-mark.png';
import googlePlay from "@/assets/google-play.png";
import greenTick from "@/assets/green-tick.svg";
import greenTickNoCircle from "@/assets/green tick no circle.svg";
import greyAdd from "@/assets/grey-add.svg";
import greyMember from "@/assets/greyMember.svg";
import greyTick from "@/assets/grey-tick.svg";
import handglass from "@/assets/hand-glass.svg"
import height from '@/assets/height.svg'
import hourglass from "@/assets/hourglass.svg";
import household from '@/assets/home.svg';
import infoIcon from "@/assets/info-red.svg"
import informationIcon from '@/assets/c-info.svg';
import informationIcon_Iceland from '@/assets/information_icon_iceland.png';
import iphone from '@/assets/iphone.svg';
import leftArrow from '@/assets/left-arrow.svg';
import loader from "@/assets/loader.svg";
import member from "@/assets/member.svg";
import members from "@/assets/group.svg";
import migration from "@/assets/migration.svg";
import multipleMembers from '@/assets/multipleMembers.svg';
import newsletter from "@/assets/newsletter.svg";
import notIncluded from '@/assets/not-included-icon.svg'
import pdf from "@/assets/pdf-icon.svg";
import pdf2 from "@/assets/pdf2.svg";
import personOutline from '@/assets/ic_person_outline.svg';
import pin from "@/assets/pin.svg";
import pinLocation from '@/assets/pinLocation.svg';
import plus from "@/assets/plus.svg";
import plus2 from "@/assets/plus2.svg";
import profile from '@/assets/person.svg';
import renewalDocument from '@/assets/renewal-document-icon.svg';
import search from "@/assets/search.svg";
import sendMessage from "@/assets/send-message.svg";
import smallBlueDownArrow from "@/assets/small-blue-down-arrow.svg";
import smallCheck from "@/assets/d-check.svg";
import smallChild from "@/assets/small-child.svg"
import smallDownArrow from "@/assets/small-down-arrow.svg";
import smallMember from "@/assets/small-member.svg"
import smallRemove from "@/assets/i-remove.svg";
import smallRightArrow from "@/assets/small-right-arrow.svg";
import trash from "@/assets/trash.svg";
import wallet from '@/assets/wallet-icon.svg';
import weight from '@/assets/weight.svg'
import whiteCheck from "@/assets/f-check.svg"
import whiteRemove from "@/assets/white-remove.svg"
import whiteRightArrow from '@/assets/small-right-arrow-white.svg'
import worldPin from "@/assets/world-pin.svg";
import wpaApp from "@/assets/wpa-app.png";
import wpaApp2 from "@/assets/wpaApp2.svg";
import wpaLogo from '@/assets/wpa-logo-svg.svg';

// import dotHalf from '@/assets/dot-full.svg'; // TODO


export function getIcon(brandName = null, iconName){
  if(brandName){
    return icons[brandName] ? icons[brandName][iconName] || icons[iconName] : icons[iconName];
  }
  else{
    return icons[iconName];
  }
}

// import dotHalf from '@/assets/dot-full.svg'; // TODO

const icons = {
  AddFamily: AddFamily,
  add: add,
  addHousehold: addHousehold,
  addWhite: addWhite,
  appStore: appStore,
  application: application,
  arrow2: arrow2,
  arrow: arrow,
  arrowRight: arrowRight,
  blackUser: blackUser,
  blueLeftArrow: blueLeftArrow,
  bluePhone: bluePhone,
  bluePin: bluePin,
  bluePlus: bluePlus,
  blueRemove: blueRemove,
  blueX: blueX,
  calendar: calendar,
  cardAm,
  cardMastercard,
  cardVisa,
  check: check,
  checkGray2: checkGray2,
  checkGray: checkGray,
  checkGreen: checkGreen,
  checkedDocumentation: checkedDocumentation,
  child: child,
  circleCheckbox: circleCheckbox,
  circleCheckboxUnchecked: circleCheckboxUnchecked,
  circleDownGreen: circleDownGreen,
  circleUpRed: circleUpRed,
  clock: clock,
  close: close,
  codeQr: codeQr,
  community: community,
  couple: couple,
  dataDownload: dataDownload,
  dotFull: dotFull,
  exclamationMark: exclamationMark,
  family: family,
  familyMultiple,
  googlePlay: googlePlay,
  greenTick: greenTick,
  greenTickNoCircle: greenTickNoCircle,
  greyAdd: greyAdd,
  greyMember: greyMember,
  greyTick: greyTick,
  handglass: handglass,
  height: height,
  hourglass: hourglass,
  household: household,
  infoIcon: infoIcon,
  informationIcon: informationIcon,
  iphone: iphone,
  leftArrow: leftArrow,
  loader: loader,
  member: member,
  members: members,
  migration: migration,
  multipleMembers: multipleMembers,
  newsletter: newsletter,
  notIncluded: notIncluded,
  pdf2: pdf2,
  pdf: pdf,
  personOutline: personOutline,
  pin: pin,
  pinLocation: pinLocation,
  plus2: plus2,
  plus: plus,
  profile: profile,
  renewalDocument: renewalDocument,
  search: search,
  sendMessage: sendMessage,
  smallBlueDownArrow: smallBlueDownArrow,
  smallCheck: smallCheck,
  smallChild: smallChild,
  smallDownArrow: smallDownArrow,
  smallMember: smallMember,
  smallRemove: smallRemove,
  smallRightArrow: smallRightArrow,
  trash: trash,
  wallet: wallet,
  weight: weight,
  whiteCheck: whiteCheck,
  whiteRemove: whiteRemove,
  whiteRightArrow: whiteRightArrow,
  worldPin: worldPin,
  wpaApp2: wpaApp2,
  wpaApp: wpaApp,
  wpaLogo: wpaLogo,
  iceland: {
    informationIcon: informationIcon_Iceland
  },
}

export default icons
