import router from '../router'

const refreshTokenExpirationOffsetMinutes = 25;
export function logout(redirectUrl, redirect = true) {
  clearRefreshToken();
  clearRefreshTokenExpirationDate();
  clearAccessToken();
  if (redirect) {
    document.location.href = redirectUrl
  }
}

export function getRefreshToken() {
  return localStorage.getItem('REFRESH_TOKEN');
}

export function getRefreshTokenExpirationDate() {
  var date = localStorage.getItem('REFRESH_TOKEN_EXPIRATION')
  return date ? new Date(parseInt(date)) : null;
}

function clearRefreshToken() {
  localStorage.removeItem('REFRESH_TOKEN');
}

function clearRefreshTokenExpirationDate() {
  localStorage.removeItem('REFRESH_TOKEN_EXPIRATION');
}

function clearAccessToken() {
  localStorage.removeItem('ACCESS_TOKEN');
}

export function getReturnUrlParameter(name) {
  return decodeURIComponent((new RegExp('[?|&|#]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
}

export function getTokenParameter(name) {
   return decodeURIComponent((new RegExp('[?|&|#]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.hash) || [null, ''])[1].replace(/\+/g, '%20')) || null;
 }

export function setRefreshToken(refreshToken) {
  localStorage.setItem('REFRESH_TOKEN', refreshToken);
  localStorage.setItem('REFRESH_TOKEN_EXPIRATION', getNewRefreshTokenExpirationDate().getTime().toString());
}

function getNewRefreshTokenExpirationDate() {
  const date = new Date();
  date.setMinutes(date.getMinutes() + refreshTokenExpirationOffsetMinutes);
  return date;
}

function getTokenExpirationDate(encodedToken) {
  const token = encodedToken;
  if (!token.exp) {
    return null;
  }
  const date = new Date(0);
  date.setUTCSeconds(token.exp - 60); // 1 minute before token get expired
  return date;
}

export function isTokenExpired(token) {
  const expirationDate = getTokenExpirationDate(token);

  var now = new Date();
  var utc_timestamp = Date.UTC(now.getUTCFullYear(),now.getUTCMonth(), now.getUTCDate(), 
      now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds())

  return expirationDate < utc_timestamp;
}

export function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};