<template>
  <b-container class="Home Container Container--mw">
    <hero>
      <template v-slot:title>With you at&nbsp;every step</template>
      <template v-slot:subtitle>Exceptional care. Expert service.<br/>Health insurance tailored to you.</template>
      <template v-slot:buttons>
        <button class="Button Button--red mt-mob" @click="$router.push({name: 'GetQuote'})">Get a quote</button>
        <button class="Button QuoteButton mt-mob" @click="$router.push({name: 'MyProfile'})">Retrieve a quote</button>
      </template>
    </hero>
  </b-container>
</template>

<script>
export default {
  name: 'Home',
  components: {
    'hero': () => import('../components/Hero')
  },
  created() {
    this.$router.push({ name: "GetQuote" });
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variable.scss';

.Home {
  @media (min-width: 800px) {
    max-width: 1280px;
  }

  .QuoteButton {
    color: $color-blue-1;
    border: 1px solid $color-blue-1;
    border-radius: 4px;
    background-color: $color-white-absolute;
    cursor: pointer;
    font: 14px 'Inter';
    font-weight: 500;
    text-align: center;
    letter-spacing: 0px;
    min-width: 130px;
    height: 36px;
    margin-right: 23px;
  }
}
@media (max-width: 1000px) {
  .mt-mob {
    margin-top: 10px;
    width: 140px;
  }
}

</style>
