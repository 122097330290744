import { lang } from "moment";

export const isAdultRelationship = (value :Number) => {
  return value == relationship.HUSBAND || value == relationship.WIFE || value == relationship.PARTNER || value == relationship.ADULT_DEPENDANT;
}

export const isChildRelationship = (value :Number) => {
  return value == relationship.SON || value == relationship.DAUGHTER || value == relationship.CHILD_DEPENDANT;
}

export const isSubscriberRelationship = (value :Number) => {
  return value == relationship.SUBSCRIBER;
}

export const relationship = {
  HUSBAND: 0,
  WIFE: 1,
  PARTNER: 2,
  SON: 3,
  DAUGHTER: 4,
  CHILD_DEPENDANT: 5,
  ADULT_DEPENDANT: 6,
  SUBSCRIBER: 7
}

export const paramTypes = {
  COVER: 1,
  SUBSCRIBER_UNDERWRITING: 2,
  DEPENDANT_UNDERWRITING: 3,
  EMAIL: 4,
  START_DATE: 5,
  NUMBER_OF_DEPENDANTS: 9,
  ADULT_DEPENDANT_UNDERWRITING: 10,
  CHILD_DEPENDANT_UNDERWRITING: 11,
  ADULT_DEPENDANT_OPTIONS: 12,
  CHILD_DEPENDANT_OPTIONS: 13,
}

export const coverType = {
  JOINT: "Joint",
  ONE_PARENT: "One Parent",
  FAMILY: "Family",
  CHILDREN: "Children",
  PARTNER: "Partner",
  PARTNER_AND_CHILDREN: "Partner And Children",
  SINGLE: "Single"
}
export const coverTypes = {
  WITH_ADULT: [coverType.FAMILY, coverType.JOINT, coverType.PARTNER, coverType.PARTNER_AND_CHILDREN],
  WITH_CHILD: [coverType.FAMILY, coverType.ONE_PARENT, coverType.CHILDREN, coverType.PARTNER_AND_CHILDREN]
}
export function getApplicationId(membershipRequest) {
  if ([2, 4].includes(membershipRequest?.type)) {
    return -membershipRequest.membershipRequestId;
  }
  return membershipRequest.personalDetails.filter(pd => pd.invitePersonType != 1)[0]?.applicationId
}
export const underwritingInviteStatus = {
  "completed":true,
  "stages":[
    {"order":110,"name":"Submitted","completed":true},
    {"order":120,"name":"Underwriting","completed":false},
    {"order":130,"name":"Review","completed":false},
    {"order":140,"name":"Completed","completed":false}
  ]
};